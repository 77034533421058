import React from 'react';

interface props {
};
interface stateType {
}

class Empty extends React.PureComponent<props, stateType> {

    constructor(props: props | Readonly<props>) {
        super(props);
    }

    componentDidMount() {
    }


    // destroy player on unmount
    componentWillUnmount() {

    }


    render() {

        return (
            <div>
            </div>
        );
    }
}

export default Empty;