import React from "react";
import {
  message,
  Tabs,
  Avatar,
  Row,
  Col,
  Card,
  Input,
  Button,
  Select,
  Table,
  Timeline,
  Pagination,
  Empty,
  Modal,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import md5 from "md5";
import momentZone from "moment-timezone";
import { encode, decode } from "js-base64";
import {
  AntDesignOutlined,
  CheckOutlined,
  DeleteOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import * as qs from "qs";
import "../myAccount.css";
import { Cookies } from "react-cookie";
import { ColumnsType } from "antd/lib/table";
import InfiniteScroll from "react-infinite-scroller";
import { QRCodeSVG } from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactGA from "react-ga4";

ReactGA.initialize("G-883ML6DGJC");
interface props {}
interface DataType {
  Id: number;
  msg: string;
  floats: string;
  total: number;
  paytime: string;
  fromLink: string;
  userid: string;
  username: string;
}
interface stateType {
  period: number;
  isMobile: boolean;
  queue: any;
  signGet: string;
  progress: boolean;
  page: string;
  pageArray: {}[];
  usernameCookies: string;
  passwordCookies: string;
  avatar: string;
  username: string;
  balance: string;
  endtime: string;
  group: string;
  balanceList: DataType[];
  checkinLoading: boolean;
  checkinStatus: boolean;
  historyList: never[];
  favoriteList: never[];
  ticketList: never[];
  hoverId: number;
  historyPage: number;
  favoritePage: number;
  ticketPage: number;
  isLoading: boolean;
  heightCard: string;
  password: string;
  passwordRe: string;
  zoneOptions: { label: string; value: string }[];
  yearOptions: string[];
  monthOptions: string[];
  dayOptions: string[];
  avatarList: never[];
  avatarLoading: boolean;
  avatarHoverId: string;
  signText: string;
  contactValue: string;
  y: string;
  m: string;
  d: string;
  zoneValue: string;
  sexValue: string;
  loginHistory: never[];
  shareList: never[];
  shareUrl: string;
  shareTotal: number;
  sharePage: number;
  balancePage: number;
  balanceTotal: number;
  copied: boolean;
  balanceCurrent: number;
  shareCurrent: number;
  lianxuqiandao: number;
  leijiqiandao: number;
}

class MyAccount extends React.PureComponent<props, stateType> {
  constructor(props: props | Readonly<props>) {
    super(props);
    console.log("running");
    const cookies = new Cookies();
    const width = window.innerWidth;
    this.state = {
      period: 0,
      isMobile: width <= 820,
      queue: [],
      lianxuqiandao: 0,
      leijiqiandao: 0,
      copied: false,
      balancePage: 0,
      balanceTotal: 0,
      balanceCurrent: 1,
      shareTotal: 0,
      sharePage: 0,
      shareList: [],
      shareUrl: "",
      shareCurrent: 1,
      loginHistory: [],
      sexValue: "",
      y: "",
      m: "",
      d: "",
      zoneValue: "暂无",
      signText: "暂无",
      contactValue: "暂无",
      avatarHoverId: "",
      avatarLoading: false,
      avatarList: [],
      avatar: "",
      username: "",
      balance: "",
      endtime: "",
      group: "",
      usernameCookies: cookies.get("username"),
      passwordCookies: cookies.get("password"),
      signGet: md5(
        momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
      ),
      progress: false,
      page: "myDetail",
      pageArray: [
        { myDetail: "myDetail" },
        { myInfo: "myInfo" },
        { history: "history" },
        { favorite: "favorite" },
        { ticket: "ticket" },
        { password: "password" },
        { loginHistory: "loginHistory" },
        { share: "share" },
      ],
      balanceList: [],
      checkinLoading: false,
      checkinStatus: false,
      historyList: [],
      favoriteList: [],
      ticketList: [],
      hoverId: 0,
      historyPage: 1,
      favoritePage: 1,
      ticketPage: 1,
      isLoading: false,
      heightCard: "100px",
      password: "",
      passwordRe: "",
      yearOptions: [
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
        "1999",
        "1998",
        "1997",
        "1996",
        "1995",
        "1994",
        "1993",
        "1992",
        "1991",
        "1990",
        "1989",
        "1988",
        "1987",
        "1986",
        "1985",
        "1984",
        "1983",
        "1982",
        "1981",
        "1980",
        "1979",
        "1978",
        "1977",
        "1976",
        "1975",
        "1974",
        "1973",
        "1972",
        "1971",
        "1970",
        "1969",
        "1968",
        "1967",
        "1966",
        "1965",
        "1964",
        "1963",
        "1962",
        "1961",
        "1960",
        "1959",
        "1958",
        "1957",
        "1956",
        "1955",
        "1954",
        "1953",
        "1952",
        "1951",
        "1950",
        "1949",
        "1948",
        "1947",
        "1946",
        "1945",
        "1944",
        "1943",
        "1942",
        "1941",
        "1940",
        "1939",
        "1938",
        "1937",
        "1936",
        "1935",
        "1934",
        "1933",
        "1932",
        "1931",
        "1930",
        "1929",
        "1928",
        "1927",
        "1926",
        "1925",
        "1924",
        "1923",
        "1922",
        "1921",
        "1920",
        "1919",
        "1918",
        "1917",
        "1916",
        "1915",
        "1914",
        "1913",
        "1912",
        "1911",
        "1910",
        "1909",
        "1908",
        "1907",
        "1906",
        "1905",
        "1904",
        "1903",
        "1902",
        "1901",
        "1900",
      ],
      dayOptions: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      monthOptions: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      zoneOptions: [
        {
          label: "阿富汗",
          value: "阿富汗",
        },
        {
          label: "奥兰群岛",
          value: "奥兰群岛",
        },
        {
          label: "阿尔巴尼亚",
          value: "阿尔巴尼亚",
        },
        {
          label: "阿尔及利亚",
          value: "阿尔及利亚",
        },
        {
          label: "美属萨摩亚",
          value: "美属萨摩亚",
        },
        {
          label: "安道尔",
          value: "安道尔",
        },
        {
          label: "安哥拉",
          value: "安哥拉",
        },
        {
          label: "安圭拉",
          value: "安圭拉",
        },
        {
          label: "南极洲",
          value: "南极洲",
        },
        {
          label: "安提瓜和巴布达",
          value: "安提瓜和巴布达",
        },
        {
          label: "阿根廷",
          value: "阿根廷",
        },
        {
          label: "亚美尼亚",
          value: "亚美尼亚",
        },
        {
          label: "阿鲁巴岛",
          value: "阿鲁巴岛",
        },
        {
          label: "澳大利亚",
          value: "澳大利亚",
        },
        {
          label: "奥地利",
          value: "奥地利",
        },
        {
          label: "阿塞拜疆",
          value: "阿塞拜疆",
        },
        {
          label: "巴哈马",
          value: "巴哈马",
        },
        {
          label: "巴林",
          value: "巴林",
        },
        {
          label: "孟加拉国",
          value: "孟加拉国",
        },
        {
          label: "巴巴多斯",
          value: "巴巴多斯",
        },
        {
          label: "白俄罗斯",
          value: "白俄罗斯",
        },
        {
          label: "比利时",
          value: "比利时",
        },
        {
          label: "伯利兹",
          value: "伯利兹",
        },
        {
          label: "贝宁",
          value: "贝宁",
        },
        {
          label: "百慕大",
          value: "百慕大",
        },
        {
          label: "不丹",
          value: "不丹",
        },
        {
          label: "玻利维亚",
          value: "玻利维亚",
        },
        {
          label: "波斯尼亚和黑塞哥维那",
          value: "波斯尼亚和黑塞哥维那",
        },
        {
          label: "博茨瓦纳",
          value: "博茨瓦纳",
        },
        {
          label: "布维岛",
          value: "布维岛",
        },
        {
          label: "巴西",
          value: "巴西",
        },
        {
          label: "英属印度洋领地",
          value: "英属印度洋领地",
        },
        {
          label: "文莱达鲁萨兰国",
          value: "文莱达鲁萨兰国",
        },
        {
          label: "保加利亚",
          value: "保加利亚",
        },
        {
          label: "布基纳法索",
          value: "布基纳法索",
        },
        {
          label: "布隆迪",
          value: "布隆迪",
        },
        {
          label: "柬埔寨",
          value: "柬埔寨",
        },
        {
          label: "喀麦隆",
          value: "喀麦隆",
        },
        {
          label: "加拿大",
          value: "加拿大",
        },
        {
          label: "佛得角",
          value: "佛得角",
        },
        {
          label: "开曼群岛",
          value: "开曼群岛",
        },
        {
          label: "中非共和国",
          value: "中非共和国",
        },
        {
          label: "乍得",
          value: "乍得",
        },
        {
          label: "智利",
          value: "智利",
        },
        {
          label: "中国",
          value: "中国",
        },
        {
          label: "圣诞岛",
          value: "圣诞岛",
        },
        {
          label: "科科斯（基林）群岛",
          value: "科科斯（基林）群岛",
        },
        {
          label: "哥伦比亚",
          value: "哥伦比亚",
        },
        {
          label: "科摩罗",
          value: "科摩罗",
        },
        {
          label: "刚果",
          value: "刚果",
        },
        {
          label: "刚果民主共和国",
          value: "刚果民主共和国",
        },
        {
          label: "库克群岛",
          value: "库克群岛",
        },
        {
          label: "哥斯达黎加",
          value: "哥斯达黎加",
        },
        {
          label: "科特迪瓦",
          value: "科特迪瓦",
        },
        {
          label: "克罗地亚",
          value: "克罗地亚",
        },
        {
          label: "古巴",
          value: "古巴",
        },
        {
          label: "库拉索",
          value: "库拉索",
        },
        {
          label: "塞浦路斯",
          value: "塞浦路斯",
        },
        {
          label: "捷克共和国",
          value: "捷克共和国",
        },
        {
          label: "丹麦",
          value: "丹麦",
        },
        {
          label: "吉布提",
          value: "吉布提",
        },
        {
          label: "多米尼克",
          value: "多米尼克",
        },
        {
          label: "多明尼加共和国",
          value: "多明尼加共和国",
        },
        {
          label: "厄瓜多尔",
          value: "厄瓜多尔",
        },
        {
          label: "埃及",
          value: "埃及",
        },
        {
          label: "萨尔瓦多",
          value: "萨尔瓦多",
        },
        {
          label: "赤道几内亚",
          value: "赤道几内亚",
        },
        {
          label: "厄立特里亚",
          value: "厄立特里亚",
        },
        {
          label: "爱沙尼亚",
          value: "爱沙尼亚",
        },
        {
          label: "埃塞俄比亚",
          value: "埃塞俄比亚",
        },
        {
          label: "福克兰群岛（马尔维纳斯）",
          value: "福克兰群岛（马尔维纳斯）",
        },
        {
          label: "法罗群岛",
          value: "法罗群岛",
        },
        {
          label: "斐济",
          value: "斐济",
        },
        {
          label: "芬兰",
          value: "芬兰",
        },
        {
          label: "法国",
          value: "法国",
        },
        {
          label: "法属圭亚那",
          value: "法属圭亚那",
        },
        {
          label: "法属波利尼西亚",
          value: "法属波利尼西亚",
        },
        {
          label: "法属南部领地",
          value: "法属南部领地",
        },
        {
          label: "加蓬",
          value: "加蓬",
        },
        {
          label: "冈比亚",
          value: "冈比亚",
        },
        {
          label: "乔治亚州",
          value: "乔治亚州",
        },
        {
          label: "德国",
          value: "德国",
        },
        {
          label: "加纳",
          value: "加纳",
        },
        {
          label: "直布罗陀",
          value: "直布罗陀",
        },
        {
          label: "希腊",
          value: "希腊",
        },
        {
          label: "格陵兰",
          value: "格陵兰",
        },
        {
          label: "格林纳达",
          value: "格林纳达",
        },
        {
          label: "瓜德罗普",
          value: "瓜德罗普",
        },
        {
          label: "关岛",
          value: "关岛",
        },
        {
          label: "危地马拉",
          value: "危地马拉",
        },
        {
          label: "根西岛",
          value: "根西岛",
        },
        {
          label: "几内亚",
          value: "几内亚",
        },
        {
          label: "几内亚比绍",
          value: "几内亚比绍",
        },
        {
          label: "圭亚那",
          value: "圭亚那",
        },
        {
          label: "海地",
          value: "海地",
        },
        {
          label: "赫德岛麦当劳群岛",
          value: "赫德岛麦当劳群岛",
        },
        {
          label: "圣座（梵蒂冈城邦）",
          value: "圣座（梵蒂冈城邦）",
        },
        {
          label: "洪都拉斯",
          value: "洪都拉斯",
        },
        {
          label: "香港",
          value: "香港",
        },
        {
          label: "匈牙利",
          value: "匈牙利",
        },
        {
          label: "冰岛",
          value: "冰岛",
        },
        {
          label: "印度",
          value: "印度",
        },
        {
          label: "印度尼西亚",
          value: "印度尼西亚",
        },
        {
          label: "伊朗伊斯兰共和国",
          value: "伊朗伊斯兰共和国",
        },
        {
          label: "伊拉克",
          value: "伊拉克",
        },
        {
          label: "爱尔兰",
          value: "爱尔兰",
        },
        {
          label: "马恩岛",
          value: "马恩岛",
        },
        {
          label: "以色列",
          value: "以色列",
        },
        {
          label: "意大利",
          value: "意大利",
        },
        {
          label: "牙买加",
          value: "牙买加",
        },
        {
          label: "日本",
          value: "日本",
        },
        {
          label: "球衣",
          value: "球衣",
        },
        {
          label: "约旦",
          value: "约旦",
        },
        {
          label: "哈萨克斯坦",
          value: "哈萨克斯坦",
        },
        {
          label: "肯尼亚",
          value: "肯尼亚",
        },
        {
          label: "基里巴斯",
          value: "基里巴斯",
        },
        {
          label: "韩国",
          value: "韩国",
        },
        {
          label: "科威特",
          value: "科威特",
        },
        {
          label: "吉尔吉斯斯坦",
          value: "吉尔吉斯斯坦",
        },
        {
          label: "老挝人民民主共和国",
          value: "老挝人民民主共和国",
        },
        {
          label: "拉脱维亚",
          value: "拉脱维亚",
        },
        {
          label: "黎巴嫩",
          value: "黎巴嫩",
        },
        {
          label: "莱索托",
          value: "莱索托",
        },
        {
          label: "利比里亚",
          value: "利比里亚",
        },
        {
          label: "阿拉伯利比亚民众国",
          value: "阿拉伯利比亚民众国",
        },
        {
          label: "列支敦士登",
          value: "列支敦士登",
        },
        {
          label: "立陶宛",
          value: "立陶宛",
        },
        {
          label: "卢森堡",
          value: "卢森堡",
        },
        {
          label: "澳门",
          value: "澳门",
        },
        {
          label: "马其顿",
          value: "马其顿",
        },
        {
          label: "马达加斯加",
          value: "马达加斯加",
        },
        {
          label: "马拉维",
          value: "马拉维",
        },
        {
          label: "马来西亚",
          value: "马来西亚",
        },
        {
          label: "马尔代夫",
          value: "马尔代夫",
        },
        {
          label: "马里",
          value: "马里",
        },
        {
          label: "马耳他",
          value: "马耳他",
        },
        {
          label: "马绍尔群岛",
          value: "马绍尔群岛",
        },
        {
          label: "马提尼克岛",
          value: "马提尼克岛",
        },
        {
          label: "毛里塔尼亚",
          value: "毛里塔尼亚",
        },
        {
          label: "毛里求斯",
          value: "毛里求斯",
        },
        {
          label: "马约特岛",
          value: "马约特岛",
        },
        {
          label: "墨西哥",
          value: "墨西哥",
        },
        {
          label: "密克罗尼西亚联邦",
          value: "密克罗尼西亚联邦",
        },
        {
          label: "摩尔多瓦",
          value: "摩尔多瓦",
        },
        {
          label: "摩纳哥",
          value: "摩纳哥",
        },
        {
          label: "蒙古",
          value: "蒙古",
        },
        {
          label: "黑山",
          value: "黑山",
        },
        {
          label: "蒙特塞拉特",
          value: "蒙特塞拉特",
        },
        {
          label: "莫桑比克",
          value: "莫桑比克",
        },
        {
          label: "缅甸",
          value: "缅甸",
        },
        {
          label: "纳米比亚",
          value: "纳米比亚",
        },
        {
          label: "瑙鲁",
          value: "瑙鲁",
        },
        {
          label: "尼泊尔",
          value: "尼泊尔",
        },
        {
          label: "荷兰",
          value: "荷兰",
        },
        {
          label: "荷属安的列斯",
          value: "荷属安的列斯",
        },
        {
          label: "新喀里多尼亚",
          value: "新喀里多尼亚",
        },
        {
          label: "新西兰",
          value: "新西兰",
        },
        {
          label: "尼加拉瓜",
          value: "尼加拉瓜",
        },
        {
          label: "尼日尔",
          value: "尼日尔",
        },
        {
          label: "尼日利亚",
          value: "尼日利亚",
        },
        {
          label: "纽埃",
          value: "纽埃",
        },
        {
          label: "诺福克岛",
          value: "诺福克岛",
        },
        {
          label: "北马里亚纳群岛",
          value: "北马里亚纳群岛",
        },
        {
          label: "挪威",
          value: "挪威",
        },
        {
          label: "阿曼",
          value: "阿曼",
        },
        {
          label: "巴基斯坦",
          value: "巴基斯坦",
        },
        {
          label: "帕劳",
          value: "帕劳",
        },
        {
          label: "巴勒斯坦国",
          value: "巴勒斯坦国",
        },
        {
          label: "巴拿马",
          value: "巴拿马",
        },
        {
          label: "巴布亚新几内亚",
          value: "巴布亚新几内亚",
        },
        {
          label: "巴拉圭",
          value: "巴拉圭",
        },
        {
          label: "秘鲁",
          value: "秘鲁",
        },
        {
          label: "菲律宾",
          value: "菲律宾",
        },
        {
          label: "皮特凯恩",
          value: "皮特凯恩",
        },
        {
          label: "波兰",
          value: "波兰",
        },
        {
          label: "葡萄牙",
          value: "葡萄牙",
        },
        {
          label: "波多黎各",
          value: "波多黎各",
        },
        {
          label: "卡塔尔",
          value: "卡塔尔",
        },
        {
          label: "团圆",
          value: "团圆",
        },
        {
          label: "罗马尼亚",
          value: "罗马尼亚",
        },
        {
          label: "俄罗斯联邦",
          value: "俄罗斯联邦",
        },
        {
          label: "卢旺达",
          value: "卢旺达",
        },
        {
          label: "圣巴泰勒米",
          value: "圣巴泰勒米",
        },
        {
          label: "圣赫勒拿",
          value: "圣赫勒拿",
        },
        {
          label: "圣基茨和尼维斯",
          value: "圣基茨和尼维斯",
        },
        {
          label: "圣卢西亚",
          value: "圣卢西亚",
        },
        {
          label: "圣马丁",
          value: "圣马丁",
        },
        {
          label: "圣皮埃尔和密克隆",
          value: "圣皮埃尔和密克隆",
        },
        {
          label: "圣文森特和格林纳丁斯",
          value: "圣文森特和格林纳丁斯",
        },
        {
          label: "萨摩亚",
          value: "萨摩亚",
        },
        {
          label: "圣马力诺",
          value: "圣马力诺",
        },
        {
          label: "圣多美和普林西比",
          value: "圣多美和普林西比",
        },
        {
          label: "沙特阿拉伯",
          value: "沙特阿拉伯",
        },
        {
          label: "塞内加尔",
          value: "塞内加尔",
        },
        {
          label: "塞尔维亚",
          value: "塞尔维亚",
        },
        {
          label: "塞舌尔",
          value: "塞舌尔",
        },
        {
          label: "塞拉利昂",
          value: "塞拉利昂",
        },
        {
          label: "新加坡",
          value: "新加坡",
        },
        {
          label: "斯洛伐克",
          value: "斯洛伐克",
        },
        {
          label: "斯洛文尼亚",
          value: "斯洛文尼亚",
        },
        {
          label: "所罗门群岛",
          value: "所罗门群岛",
        },
        {
          label: "索马里",
          value: "索马里",
        },
        {
          label: "南非",
          value: "南非",
        },
        {
          label: "南乔治亚岛和三明治岛。",
          value: "南乔治亚岛和三明治岛。",
        },
        {
          label: "西班牙",
          value: "西班牙",
        },
        {
          label: "斯里兰卡",
          value: "斯里兰卡",
        },
        {
          label: "苏丹",
          value: "苏丹",
        },
        {
          label: "苏里南",
          value: "苏里南",
        },
        {
          label: "斯瓦尔巴和扬马延",
          value: "斯瓦尔巴和扬马延",
        },
        {
          label: "斯威士兰",
          value: "斯威士兰",
        },
        {
          label: "瑞典",
          value: "瑞典",
        },
        {
          label: "瑞士",
          value: "瑞士",
        },
        {
          label: "阿拉伯叙利亚共和国",
          value: "阿拉伯叙利亚共和国",
        },
        {
          label: "台湾",
          value: "台湾",
        },
        {
          label: "塔吉克斯坦",
          value: "塔吉克斯坦",
        },
        {
          label: "坦桑尼亚",
          value: "坦桑尼亚",
        },
        {
          label: "泰国",
          value: "泰国",
        },
        {
          label: "东帝汶",
          value: "东帝汶",
        },
        {
          label: "多哥",
          value: "多哥",
        },
        {
          label: "托克劳",
          value: "托克劳",
        },
        {
          label: "汤加",
          value: "汤加",
        },
        {
          label: "特立尼达和多巴哥",
          value: "特立尼达和多巴哥",
        },
        {
          label: "突尼斯",
          value: "突尼斯",
        },
        {
          label: "火鸡",
          value: "火鸡",
        },
        {
          label: "土库曼斯坦",
          value: "土库曼斯坦",
        },
        {
          label: "特克斯和凯科斯群岛",
          value: "特克斯和凯科斯群岛",
        },
        {
          label: "图瓦卢",
          value: "图瓦卢",
        },
        {
          label: "乌干达",
          value: "乌干达",
        },
        {
          label: "乌克兰",
          value: "乌克兰",
        },
        {
          label: "阿拉伯联合酋长国",
          value: "阿拉伯联合酋长国",
        },
        {
          label: "英国",
          value: "英国",
        },
        {
          label: "美国",
          value: "美国",
        },
        {
          label: "美国离岛",
          value: "美国离岛",
        },
        {
          label: "乌拉圭",
          value: "乌拉圭",
        },
        {
          label: "乌兹别克斯坦",
          value: "乌兹别克斯坦",
        },
        {
          label: "瓦努阿图",
          value: "瓦努阿图",
        },
        {
          label: "委内瑞拉",
          value: "委内瑞拉",
        },
        {
          label: "越南",
          value: "越南",
        },
        {
          label: "英属维尔京群岛",
          value: "英属维尔京群岛",
        },
        {
          label: "美国维尔京群岛",
          value: "美国维尔京群岛",
        },
        {
          label: "瓦利斯和富图纳群岛",
          value: "瓦利斯和富图纳群岛",
        },
        {
          label: "撒哈拉沙漠西部",
          value: "撒哈拉沙漠西部",
        },
        {
          label: "也门",
          value: "也门",
        },
        {
          label: "赞比亚",
          value: "赞比亚",
        },
        {
          label: "津巴布韦",
          value: "津巴布韦",
        },
      ],
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    if (cookies.get("vef") == null || cookies.get("vef") != "active") {
      window.location.replace("/account?action=verify");
      return false;
    }

    var check = localStorage.getItem("localqueue");

    if (check != null) {
      this.setState({
        queue: JSON.parse(check),
      });
    }

    if (
      this.state.usernameCookies == undefined ||
      this.state.usernameCookies == null ||
      this.state.usernameCookies == "guest"
    ) {
      window.location.href = "/index";
      return false;
    }

    const _this = this;
    // instantiate Video.js
    window.addEventListener("resize", this.handleWindowSizeChange);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query = params.get("page");
    if (query != null) {
      this.pageChange("../myAccount?page=" + query);
    } else {
      window.location.href = "/myAccount?page=myDetail";
      //this.pageChange("../myAccount?page=myDetail");
    }
  }

  // destroy player on unmount
  componentWillUnmount() {}

  handleWindowSizeChange = () => {
    return () => {
      //this.setState({ width: window.innerWidth });
    };
  };

  deleteItem = (Id: any) => {
    if (Id == 0) {
      localStorage.removeItem("localqueue");
      return false;
    }

    var check = localStorage.getItem("localqueue");
    var checkJson = check != null ? JSON.parse(check) : [];
    var newJson: any = [];
    if (check != null) {
      for (var i = 0; i < checkJson.length; i++) {
        if (checkJson[i].Id != Id) {
          newJson.push(checkJson[i]);
          console.log(checkJson[i]);
        }
      }
      console.log(checkJson);
      localStorage.setItem("localqueue", JSON.stringify(newJson));
      var checknew = localStorage.getItem("localqueue");

      if (checknew != null) {
        this.setState({
          queue: JSON.parse(checknew),
        });
      }
    }
  };

  handleWidth = () => {
    const myElement = document.querySelector(".ant-card-cover");
    if (myElement?.clientWidth != null) {
      if (this.state.heightCard == "100px") {
        this.setState({
          heightCard: myElement?.clientWidth * 0.7 + "px",
        });
        console.log(myElement?.clientWidth * 0.7 + "px");
      } else {
        console.log("not 100px");
      }
    }
  };

  ticketListMore() {
    this.handleWidth();
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    if (this.state.isLoading == true) {
      return false;
    }
    if (this.state.ticketPage == 1 && this.state.ticketList.length > 0) {
      return false;
    }
    _this.setState({
      ticketPage: this.state.ticketPage + 1,
      isLoading: true,
    });
    axios
      .post(
        window.apiUrl +
          "/Application/ticket?page=" +
          this.state.ticketPage +
          "&sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any }) {
        if (_this.state.ticketPage == 2 && _this.state.ticketList.length != 0) {
          return false;
        }

        var decode5 = _this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        _this.setState({
          ticketList:
            _this.state.ticketPage == 2
              ? data
              : _this.state.ticketList.concat(data),
          isLoading: false,
        });
      })
      .catch(function (error: any) {});
  }

  historyLoadMore() {
    this.handleWidth();
    if (this.state.isLoading == true) {
      return false;
    }
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({
      historyPage: this.state.historyPage + 1,
      isLoading: true,
    });
    axios
      .post(
        window.apiUrl +
          "/Application/read?page=" +
          this.state.historyPage +
          "&sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any }) {
        if (
          _this.state.historyPage == 2 &&
          _this.state.historyList.length != 0
        ) {
          return false;
        } else {
          var decode5 = _this.keyHash(response.data);
          let reg = new RegExp("\\+", "g"); //g代表全部
          let newMsg = decode5.replace(reg, "  ");
          var result = JSON.parse(newMsg);
          const data = result;

          _this.setState({
            historyList:
              _this.state.historyPage == 2
                ? data
                : _this.state.historyList.concat(data),
            isLoading: false,
          });
        }
      })
      .catch(function (error: any) {});
  }
  favoriteListMore() {
    this.handleWidth();
    if (this.state.isLoading == true) {
      return false;
    }
    if (this.state.favoritePage == 1 && this.state.favoriteList.length > 0) {
      return false;
    }
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({
      favoritePage: this.state.favoritePage + 1,
      isLoading: true,
    });
    axios
      .post(
        window.apiUrl +
          "/Application/favorite?page=" +
          this.state.favoritePage +
          "&sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any }) {
        if (
          _this.state.favoritePage == 2 &&
          _this.state.favoriteList.length > 0
        ) {
          return false;
        }
        var decode5 = _this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        _this.setState({
          favoriteList:
            _this.state.favoritePage == 2
              ? data
              : _this.state.favoriteList.concat(data),
          isLoading: false,
        });
      })
      .catch(function (error: any) {});
  }
  balanceHistoryLoad(page: string) {
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    axios
      .post(
        window.apiUrl +
          "/Application/balanceHistory?sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
          page: page,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any }) {
        var decode5 = _this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        _this.setState({
          balanceList: data.list,
        });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        console.log(_this.state.balanceList);
      })
      .catch(function (error: any) {});
  }

  balancePageLoad() {
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    axios
      .post(
        window.apiUrl +
          "/Application/balancePagination?sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any }) {
        var decode5 = _this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        _this.setState({
          balancePage: data.page,
          balanceTotal: data.total,
        });
      })
      .catch(function (error: any) {});
  }

  axiosGet_checkin() {
    const cookies = new Cookies();
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({});
    axios
      .post(
        window.apiUrl + "/Application/checkin?sign=" + this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any }) {
        var decode5 = _this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;

        _this.setState({
          avatar: data.avatar,
          username: data.username,
          balance: data.balance,
          endtime: data.endtime,
          group: data.group,
          lianxuqiandao: data.checkinNum,
          leijiqiandao: data.checkinTotal,
          period: data.period,
        });
        cookies.set("avatar", data.avatar);

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        _this.balanceHistoryLoad("1");
      })
      .catch(function (error: any) {});
  }

  check_checkin() {
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({});
    axios
      .post(
        window.apiUrl + "/Application/check_checkin?sign=" + this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any; status: any; headers: any }) {
        if (response.data == "error") {
          _this.setState({
            checkinStatus: true,
          });
        }
        if (response.data == "yes") {
          _this.setState({
            checkinStatus: false,
          });
        }
      })
      .catch(function (error: any) {});
  }

  enter_checkin() {
    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({
      checkinLoading: true,
    });
    axios
      .post(
        window.apiUrl +
          "/Application/checkin_action?type=app&sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: {
        data: { msg: string };
        status: any;
        headers: any;
      }) {
        console.log(response.status);
        _this.success("签到成功");
        _this.setState({
          checkinLoading: false,
        });
        _this.userIndex();
        _this.success("您的账户信息已刷新");
      })
      .catch(function (error: any) {
        console.log(error);
        _this.error("您今日已经签到过了，请明日再来");
        _this.setState({
          checkinLoading: false,
        });
      });
  }

  getMyInfo() {
    axios
      .post(
        window.apiUrl + "/Application/getMyinfo?sign=" + this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any; status: any; headers: any }) => {
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        console.log(data);
        this.setState({
          sexValue: data.list[0].sex,
          y:
            data.list[0].bornValue != "" && data.list[0].bornValue != null
              ? data.list[0].bornValue.substring(0, 4)
              : "",
          m:
            data.list[0].bornValue != "" && data.list[0].bornValue != null
              ? data.list[0].bornValue.substring(5, 7)
              : "",
          d:
            data.list[0].bornValue != "" && data.list[0].bornValue != null
              ? data.list[0].bornValue.substring(8, 10)
              : "",
          zoneValue: data.list[0].zoneValue,
          signText: data.list[0].signature,
          contactValue: data.list[0].contactValue,
        });
        setTimeout(() => {
          console.log(this.state.sexValue);
        }, 200);
      })
      .catch(function (error: any) {});
  }

  updateMyInfo() {
    var born = this.state.y + "-" + this.state.m + "-" + this.state.d;
    if (born != "--") {
      console.log(this.state.y + "-" + this.state.m + "-" + this.state.d);
      var date = moment(
        this.state.y + "-" + this.state.m + "-" + this.state.d,
        "YYYY-MM-DD",
        true
      ).isValid();
      console.log(date);
      if (date === true) {
      } else {
        this.error("日期错误，请选择正确日期");
        return false;
      }
    }

    console.log(born);
    axios
      .post(
        window.apiUrl + "/Application/updateMyInfo?sign=" + this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
          avatarId: this.state.avatarHoverId,
          sexValue: this.state.sexValue,
          signText: this.state.signText,
          contactValue: this.state.contactValue,
          bornValue: born == "--" ? "" : born,
          zoneValue: this.state.zoneValue,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any; status: any; headers: any }) => {
        this.success("个人信息更新成功");
        this.getMyInfo();
      })
      .catch((error: any) => {
        this.error("您提交的信息有误，请重试");
        this.getMyInfo();
      });
  }

  userIndex() {
    this.setState({
      avatar: "",
      username: "",
      balance: "",
      endtime: "",
      group: "",
    });
    this.check_checkin();
    this.axiosGet_checkin();
  }

  avatarList() {
    this.setState({
      historyPage: this.state.historyPage + 1,
      isLoading: true,
      avatarLoading: true,
    });

    axios
      .post(
        window.apiUrl +
          "/Application/AvatarList?types=all&sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        console.log(data);
        this.setState({
          avatarList: data.list,
          avatarLoading: false,
        });
      })
      .catch(function (error: any) {});
  }

  shareLoad(page: any) {
    axios
      .post(
        window.apiUrl + "/Application/shareList?sign=" + this.state.signGet,
        qs.stringify({
          page: page,
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        console.log(data);
        this.setState({
          shareList: data.list,
          shareUrl: window.location.origin + "/index?ref=" + data.userId,
        });
      })
      .catch(function (error: any) {});
  }

  sharePageLoad() {
    axios
      .post(
        window.apiUrl +
          "/Application/SharelistPagination?sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        console.log(data);
        this.setState({
          shareTotal: data.total,
          sharePage: data.page,
        });
      })
      .catch(function (error: any) {});
  }

  onChangeSharePagination = (key: number) => {
    console.log(key);
    this.shareLoad(key.toString());
    this.setState({ shareCurrent: key });
  };
  onChangeBalancePagination = (key: number) => {
    console.log(key);
    this.balanceHistoryLoad(key.toString());
    this.setState({ balanceCurrent: key });
  };
  keyHash = (key: string) => {
    if (key == "400" || key.length == 0) {
      return "";
    } else {
      var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .add(1, "hours")
        .format("YYYY-MM-DD-HH");
      var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .subtract(1, "hours")
        .format("YYYY-MM-DD-HH");

      var add = encode(md5(date1 + "sign91")).replace("=", "");
      var subtract = encode(md5(date2 + "sign91")).replace("=", "");

      var length1 = key.length;
      var length2 = length1 - 20;
      var content1 = key.substring(length2, length1);
      var content2 = key.substring(0, length2);
      ///////////////////////////////////////////
      var keys = encode(this.state.signGet).replace("=", "");
      let reg = new RegExp("END", "g"); //g代表全部
      var keyHashFirst = content1.replace(reg, "");
      var keyHash = content2 + keyHashFirst;
      var keyResult = keyHash.replace(keys, "");
      var keyResult1 = keyResult.replace(subtract, "");
      var keyResult2 = keyResult1.replace(add, "");
      return decode(keyResult2.toString());
    }
  };

  success = (msg: any) => {
    message.success(msg);
  };

  error = (msg: any) => {
    message.error(msg);
  };

  warning = (msg: any) => {
    message.warning(msg);
  };

  pageChange = (key: any) => {
    this.setState({
      historyPage: 1,
      favoritePage: 1,
      ticketPage: 1,
      historyList: [],
      favoriteList: [],
      ticketList: [],
      isLoading: false,
    });
    window.history.replaceState(null, "", key);
    if (key == "../myAccount?page=myDetail") {
      document.title = "我的账户 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=myDetail",
        title: "我的账户 - " + window.title,
      });
      this.axiosGet_checkin();
      this.check_checkin();
      if (this.state.balanceTotal == 0) {
        this.balancePageLoad();
      }
    }
    if (key == "../myAccount?page=queue") {
      document.title = "稍后观看 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=queue",
        title: "稍后观看 - " + window.title,
      });
      this.handleWidth();
      //this.historyLoadMore();
    }
    if (key == "../myAccount?page=history") {
      document.title = "观看历史 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=history",
        title: "观看历史 - " + window.title,
      });
      //this.historyLoadMore();
    }
    if (key == "../myAccount?page=favorite") {
      document.title = "我的收藏 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=favorite",
        title: "我的收藏 - " + window.title,
      });
      //this.favoriteListMore();
    }
    if (key == "../myAccount?page=ticket") {
      document.title = "点播券记录 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=ticket",
        title: "点播券记录 - " + window.title,
      });
      // this.ticketListMore();
    }
    if (key === "../myAccount?page=myInfo") {
      document.title = "我的信息 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=myInfo",
        title: "我的信息 - " + window.title,
      });
      this.setState({ avatarList: [] });
      this.getMyInfo();
    }
    if (key == "../myAccount?page=loginHistory") {
      document.title = "登录记录 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=loginHistory",
        title: "登录记录 - " + window.title,
      });
      this.LoginHistoryAxios();
    }
    if (key == "../myAccount?page=share") {
      document.title = "链接推广 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/myAccount?page=share",
        title: "链接推广 - " + window.title,
      });
      this.shareLoad("1");
      if (this.state.shareTotal == 0) {
        this.sharePageLoad();
      }
    }
    var result = key.toString().replaceAll("../myAccount?page=", "");
    this.setState({ page: result });
  };

  changeHoverId = (id: any) => {
    return () => {
      this.setState({ hoverId: id });
    };
  };
  leaveHoverId = () => {
    return () => {
      this.setState({ hoverId: 0 });
    };
  };
  handlePassword = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ password: value.target.value });
  };

  handlePasswordRe = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ passwordRe: value.target.value });
  };

  sumbitNewPassword = () => {
    if (this.state.password != this.state.passwordRe) {
      this.error("两次输入的密码不匹配，请检查");
      return false;
    }
    if (this.state.password.length > 32 || this.state.password.length < 8) {
      this.error("密码长度错误");
      return false;
    }
    if (this.state.passwordRe.length > 32 || this.state.passwordRe.length < 8) {
      this.error("密码长度错误");
      return false;
    }
    axios
      .post(
        window.apiUrl +
          "/Application/changepassword_action?sign=" +
          this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
          newpassword: this.state.passwordRe,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(
        (response: { data: { msg: string }; status: any; headers: any }) => {
          console.log(response.status);
          if (response.status == 200) {
            this.success("修改成功");
          }
        }
      )
      .catch((error: any) => {
        this.success("修改失败");
      });
  };

  avatarClick = (Id: any) => {
    console.log(Id);
    this.setState({
      avatarHoverId: Id,
      avatarList: [],
    });
  };

  avatarCancel = () => {
    this.setState({
      avatarList: [],
      avatarHoverId: "",
    });
  };

  handleSignText = (value: { target: { value: any } }) => {
    console.log(value.target.value);
    this.setState({ signText: value.target.value });
  };

  handleContact = (value: { target: { value: any } }) => {
    console.log(value.target.value);
    this.setState({ contactValue: value.target.value });
  };

  LoginHistoryAxios() {
    axios
      .post(
        window.apiUrl + "/Application/LoginHistory?sign=" + this.state.signGet,
        qs.stringify({
          username: this.state.usernameCookies,
          password: this.state.passwordCookies,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        console.log(data);
        this.setState({
          loginHistory: data,
        });
      })
      .catch(function (error: any) {});
  }

  selectY = (value: any) => {
    this.setState({ y: value });
  };
  selectM = (value: any) => {
    this.setState({ m: value });
  };
  selectD = (value: any) => {
    this.setState({ d: value });
    setTimeout(() => {
      console.log(this.state.y + "-" + this.state.m + "-" + this.state.d);
      var date = moment(
        this.state.y + "-" + this.state.m + "-" + this.state.d,
        "YYYY-MM-DD",
        true
      ).isValid();
      console.log(date);
      if (date === true) {
      } else {
        this.error("日期错误，请选择正确日期");
      }
    }, 200);
  };
  postidBase(Id: string) {
    if (Id != null) {
      return encode(Id).replace("=", "_");
    }
  }

  goTo = (id: any) => {
    let navigate = useNavigate();
    return () => {
      //navigate("../i", { replace: true });
      setTimeout(() => {
        navigate("/detail?v=" + this.postidBase(id), { replace: true });
      }, 100);
    };
  };

  render() {
    const { TabPane } = Tabs;

    const { Option } = Select;

    interface DataType {
      Id: number;
      msg: string;
      floats: string;
      total: number;
      paytime: string;
      fromLink: string;
      userid: string;
      username: string;
    }

    const columns: ColumnsType<DataType> = [
      {
        title: "操作类型",
        dataIndex: "msg",
        key: "msg",
      },
      {
        title: "余额变动",
        dataIndex: "floats",
        key: "floats",
      },
      {
        title: "最新余额",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "操作时间",
        dataIndex: "paytime",
        key: "paytime",
      },
    ];

    interface ShareType {
      ip: number;
      pushTime: string;
      balance: string;
      amount: number;
    }

    const columns_share: ColumnsType<ShareType> = [
      {
        title: "IP地址",
        dataIndex: "ip",
        key: "ip",
      },
      {
        title: "访问时间",
        dataIndex: "pushTime",
        key: "pushTime",
      },
      {
        title: "最新余额",
        dataIndex: "balance",
        key: "balance",
      },
      {
        title: "余额变化",
        dataIndex: "amount",
        key: "amount",
      },
    ];

    function ReadItem(props: {
      items: any;
      height: any;
      changeHoverId: any;
      hoverId: any;
      leaveHoverId: any;
      type: any;
      historyLoad: any;
      favoriteLoad: any;
      ticketLoad: any;
      postidBase: any;
      LinkTo: any;
      deleteItem: any;
    }) {
      //const alertpop = alertpop()
      const items = props.items;
      const height = props.height;
      const changeHoverId = props.changeHoverId;
      const hoverId = props.hoverId;
      const leaveHoverId = props.leaveHoverId;
      const type = props.type;
      const historyLoad = props.historyLoad;
      const favoriteLoad = props.favoriteLoad;
      const ticketLoad = props.ticketLoad;
      const postidBase = props.postidBase;
      const goTo = props.LinkTo;
      const deleteItem = props.deleteItem;
      //const hoverId = props.hoverId
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      //style={{ height: height, width: "100%" }}
      const listItem = items.map(
        (
          item: {
            postid: React.Key | null | undefined;
            Id: React.Key | null | undefined;
            duration:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            source: string;
            poster: string;
            title:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            username:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            readtime: any;
            addtime: any;
            views: any;
          },
          i: any
        ) =>
          item.title != null && item.title != undefined ? (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={4}
              xxl={4}
              key={i.toString()}
              onMouseLeave={leaveHoverId(
                type == "history" ? item.Id : item.postid
              )}
            >
              <Card
                hoverable
                cover={
                  <a>
                    <div
                      className="videoPoster"
                      onMouseOver={changeHoverId(
                        type == "history" || type == "queue"
                          ? item.Id
                          : item.postid
                      )}
                      onMouseLeave={leaveHoverId(
                        type == "history" || type == "queue"
                          ? item.Id
                          : item.postid
                      )}
                    >
                      <div className="duration">{item.duration}</div>
                      <div className="videoBox-bottom"></div>
                      <div className="videoCard">
                        {type == "queue" ? (
                          <div
                            className="deleteItem"
                            onClick={() =>
                              deleteItem(
                                type == "history" || type == "queue"
                                  ? item.Id
                                  : item.postid
                              )
                            }
                          >
                            <DeleteOutlined />
                          </div>
                        ) : null}
                        {height != "100px" ? (
                          type == "queue" ? (
                            <img
                              onClick={goTo(item.Id)}
                              src={
                                window.cacheUrl +
                                "/" +
                                item.poster +
                                "/thumb.jpg"
                              }
                              style={{ height: height }}
                            />
                          ) : (
                            <img
                              onClick={goTo(
                                type == "history" ? item.Id : item.postid
                              )}
                              src={
                                window.cacheUrl +
                                "/" +
                                item.source +
                                "/thumb.jpg"
                              }
                              style={{ height: height }}
                            />
                          )
                        ) : null}
                      </div>

                      {hoverId === item.Id && type == "history" ? (
                        <div
                          className="play_hover_btn"
                          style={{ height: height }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="rgba(255, 255, 255, 0.8)"
                            className="feather"
                          >
                            <path d="M8,5.14V19.14L19,12.14L8,5.14Z"></path>
                          </svg>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {hoverId === item.postid && type != "history" ? (
                        <div
                          className="play_hover_btn"
                          style={{ height: height }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="rgba(255, 255, 255, 0.8)"
                            className="feather"
                          >
                            <path d="M8,5.14V19.14L19,12.14L8,5.14Z"></path>
                          </svg>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </a>
                }
              >
                <Link
                  to={
                    type == "history"
                      ? "/detail?v=" + postidBase(item.Id)
                      : "/detail?v=" + postidBase(item.postid)
                  }
                >
                  <p className="videoName" onMouseLeave={leaveHoverId(item.Id)}>
                    {item.title}
                  </p>
                  <div
                    className="videoInfoMore"
                    onMouseLeave={leaveHoverId(item.Id)}
                  >
                    {type == "history" ? (
                      <span className="smalltext">
                        观看时间：{item.readtime}
                      </span>
                    ) : (
                      ""
                    )}
                    {type == "favorite" ? (
                      <span className="smalltext">
                        收藏时间：{item.addtime}
                      </span>
                    ) : (
                      ""
                    )}
                    {type == "ticket" ? (
                      <span className="smalltext">{item.username}</span>
                    ) : (
                      ""
                    )}
                    {type == "ticket" ? (
                      <span className="smalltext">{item.views}次观看</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              </Card>
            </Col>
          ) : null
      );

      return (
        <Row onMouseLeave={leaveHoverId("2")} style={{ overflow: "hidden" }}>
          {type == "queue" ? (
            <InfiniteScroll
              loadMore={() => null}
              hasMore={true}
              loader={
                <div className="myAccountLoading" key={"queueKey"}>
                  <div className="loading-spinner"></div>
                </div>
              }
              useWindow={true}
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "row wrap",
                overflow: "hidden",
              }}
            >
              {listItem}
            </InfiniteScroll>
          ) : (
            ""
          )}
          {type == "history" ? (
            <InfiniteScroll
              loadMore={historyLoad}
              hasMore={true}
              loader={
                <div className="myAccountLoading" key={"historyKey"}>
                  <div className="loading-spinner"></div>
                </div>
              }
              useWindow={true}
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "row wrap",
                overflow: "hidden",
              }}
            >
              {listItem}
            </InfiniteScroll>
          ) : (
            ""
          )}
          {type == "favorite" ? (
            <InfiniteScroll
              loadMore={favoriteLoad}
              hasMore={true}
              loader={
                <div className="myAccountLoading" key={"historyKey"}>
                  <div className="loading-spinner"></div>
                </div>
              }
              useWindow={true}
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "row wrap",
                overflow: "hidden",
              }}
            >
              {listItem}
            </InfiniteScroll>
          ) : (
            ""
          )}
          {type == "ticket" ? (
            <InfiniteScroll
              loadMore={ticketLoad}
              hasMore={true}
              loader={
                <div className="myAccountLoading" key={"historyKey"}>
                  <div className="loading-spinner"></div>
                </div>
              }
              useWindow={true}
              style={{
                width: "100%",
                display: "flex",
                flexFlow: "row wrap",
                overflow: "hidden",
              }}
            >
              {listItem}
            </InfiniteScroll>
          ) : (
            ""
          )}
        </Row>
      );
    }

    function CountrySelect(props: { list: any; zoneValue: string }) {
      const allCountry = props.list;
      const zoneValue = props.zoneValue;

      const listItem = allCountry.map(
        (item: { lable: string; value: string }, i: any) => (
          <Option value={item.value} key={item.lable + i.toString()}>
            {item.value}
          </Option>
        )
      );
      return (
        <Select defaultValue={zoneValue} style={{ width: 120 }}>
          {listItem}
        </Select>
      );
    }

    function YearSelect(props: { list: any; y: any; selectY: any }) {
      const listList = props.list;
      const y = props.y;
      const selectY = props.selectY;

      const listItem = listList.map((item: any, i: number) => (
        <Option value={listList[i]} key={listList[i] + i.toString()}>
          {listList[i]} 年
        </Option>
      ));
      return (
        <Select defaultValue={y} style={{ width: 120 }} onChange={selectY}>
          {listItem}
        </Select>
      );
    }
    function MonthSelect(props: { list: any; m: any; selectM: any }) {
      const listList = props.list;
      const m = props.m;
      const selectM = props.selectM;

      const listItem = listList.map((item: any, i: number) => (
        <Option value={listList[i]} key={listList[i] + i.toString()}>
          {listList[i]}月
        </Option>
      ));
      return (
        <Select defaultValue={m} style={{ width: 120 }} onChange={selectM}>
          {listItem}
        </Select>
      );
    }
    function DaySelect(props: { list: any; d: any; selectD: any }) {
      const listList = props.list;
      const d = props.d;
      const selectD = props.selectD;

      const listItem = listList.map((item: any, i: number) => (
        <Option value={listList[i]} key={listList[i] + i.toString()}>
          {listList[i]}日
        </Option>
      ));
      return (
        <Select defaultValue={d} style={{ width: 120 }} onChange={selectD}>
          {listItem}
        </Select>
      );
    }
    function SexSelect(props: { defaultValue: any }) {
      const defaultValue = props.defaultValue;
      return (
        <Select defaultValue={defaultValue} style={{ width: 120 }}>
          <Option value="1">男</Option>
          <Option value="2">女</Option>
          <Option value="0">保密</Option>
        </Select>
      );
    }

    function AvatarGet(props: {
      list: any;
      avatarHoverId: any;
      avatarClick: any;
      refresh: any;
      avatarCancel: any;
    }) {
      const listList = props.list;
      const avatarHoverId = props.avatarHoverId;
      const avatarClick = props.avatarClick;
      const refresh = props.refresh;
      const avatarCancel = props.avatarCancel;

      const listItem = listList.map((item: { avatarId: string }, i: number) => (
        <div
          className="ant-avatar"
          key={item.avatarId + i.toString()}
          onClick={() => avatarClick(item.avatarId)}
          style={
            avatarHoverId == item.avatarId ? { border: "2px solid white" } : {}
          }
        >
          <img
            style={{ width: "64px", height: "64px", borderRadius: "64px" }}
            src={
              "https://media.91hub-usercontent.work:2096/avatar/" +
              item.avatarId +
              ".jpg"
            }
          />
        </div>
      ));
      return (
        <div className="avatar-body">
          {listItem}
          <div style={{ marginTop: "20px" }}>
            <Button
              onClick={refresh}
              type="primary"
              style={{ width: "100px", marginRight: "20px" }}
            >
              换一换
            </Button>
            <Button
              onClick={avatarCancel}
              type="primary"
              style={{ width: "100px" }}
            >
              取消操作
            </Button>
          </div>
        </div>
      );
    }

    function LoginHistoryGet(props: { list: any }) {
      const listList = props.list;
      const listItem = listList.map((item: any, i: number) => (
        <Timeline.Item key={item.logindate} label={item.logindate}>
          {item.loginip}&nbsp; ({item.info})
        </Timeline.Item>
      ));
      return <Timeline mode={"left"}>{listItem}</Timeline>;
    }

    function ShareInput(props: { url: any }) {
      const url = props.url;
      return (
        <Input
          defaultValue={url == "" ? "获取中" : url}
          disabled={true}
          prefix={<UserOutlined />}
          style={{ maxWidth: "500px" }}
        />
      );
    }
    const info = () => {
      Modal.info({
        title: "点播使用说明",
        content: (
          <div>
            <p>
              点播券使用限制说明：新用户在点播视频后，48小时内可以无限次观看。而老用户在累计签到30/90/180/365天后，点播后的30/90/180/365天内可以无限次观看。此外，您的推广人数也可自动转化为签到天数（每推广1人可转化为1天签到）。需要注意的是，在签到未满30天时，点播期限仍为48小时；在签到未满90天时，点播期限仍为30天，以此类推。
            </p>
          </div>
        ),
        onOk() {},
      });
    };

    return (
      <div
        id="myAccountPage"
        className={this.state.isMobile == true ? "myAccountMobile" : ""}
      >
        {this.state.heightCard == "100px" ? (
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
              <div className="ant-card-cover"></div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {/*
                <div className='account-header'>
                    <h1>个人中心</h1>
                </div>
                <TabList checkinLoading={this.state.checkinLoading} enter_checkin={this.enter_checkin.bind(this)} page={this.state.page} balanceHistory={this.state.balanceList} username={this.state.username} avatar={this.state.avatar} balance={this.state.balance} endtime={this.state.endtime} group={this.state.group} /> */}

        <Tabs
          activeKey={this.state.page}
          onChange={(key: string) =>
            this.pageChange("../myAccount?page=" + key)
          }
        >
          <TabPane tab="我的账户" key="myDetail">
            {this.state.group == "" ? (
              <div
                className="myAccountLoading"
                key={"historyKey"}
                style={{ margin: "30px 0px" }}
              >
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <div className="myDetail">
                <div className="tabPane-title">
                  <h2>账户详情</h2>
                </div>
                <div className="tabPane-body">
                  <Avatar
                    size={{ xs: 80, sm: 80, md: 80, lg: 80, xl: 80, xxl: 100 }}
                    src={
                      this.state.avatar.substring(0, 4) == "http"
                        ? this.state.avatar
                        : window.cacheUrl + "/" + this.state.avatar
                    }
                    icon={<AntDesignOutlined />}
                  />

                  <h3>{this.state.username}</h3>
                  <h3>
                    {this.state.group.substring(0, 3) == "VIP" ? (
                      <span className="vipICON">VIP</span>
                    ) : null}{" "}
                    {this.state.group}
                  </h3>
                  <br />
                  {/**
                                             * 
                                             *                                         <p>点播券余额：{this.state.balance}张</p>
                                        <p>到期时间：{this.state.endtime}</p>
                                             * 
                                             */}

                  {/**
                                             * 
                                             * this.state.checkinStatus == true ? <Button disabled={this.state.checkinLoading == true ? true : false} loading={this.state.checkinLoading} onClick={() => this.enter_checkin()} type="primary" shape="round" icon={<CheckOutlined />} >
                                                马上签到
                                            </Button> :
                                                ""
                                             */}
                  <div className="figures">
                    <div className="figure-card mini-card">
                      <span className="figure active">
                        {this.state.lianxuqiandao}
                      </span>
                      <div className="attention">
                        <span className="text">连续签到/天</span>
                      </div>
                    </div>
                    <div className="figure-card mid-card web-only">
                      <span className="figure">{this.state.leijiqiandao}</span>
                      <div className="attention">
                        <span className="text">累计签到/天</span>
                      </div>
                    </div>
                    <div className="figure-card large-card">
                      <span className="figure">{this.state.balance}</span>
                      <div className="attention">
                        <span className="text">点播券/张</span>
                        <span className="tooltip web-only">
                          <span className="byte-tooltip__wrapper"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="code-calender">
                    <Button
                      disabled={
                        this.state.checkinLoading == true ? true : false
                      }
                      loading={this.state.checkinLoading}
                      onClick={() => this.enter_checkin()}
                      type="primary"
                      shape="round"
                      className="signin btn"
                      icon={<CheckOutlined />}
                    >
                      {this.state.checkinStatus == true
                        ? "马上签到"
                        : "今天已签到"}
                    </Button>
                  </div>
                  <p>下次签到时间：{this.state.endtime}</p>
                  <p>点播后有效天数：{this.state.period}&nbsp;天</p>
                  <p
                    onClick={info}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    点播券使用限制：点击查看详情
                  </p>
                </div>
                <div className="tabPane-title">
                  <h2>余额记录</h2>
                </div>
                <div className="tabPane-body">
                  <Table
                    columns={columns}
                    dataSource={this.state.balanceList}
                    pagination={{ pageSize: 12 }}
                    rowKey={(columns) => columns.Id}
                  />
                  <br />
                  <Pagination
                    current={this.state.balanceCurrent}
                    onChange={this.onChangeBalancePagination}
                    defaultCurrent={1}
                    total={this.state.balanceTotal}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab="我的信息" key="myInfo">
            <div className="tabPane-title">
              <h2>我的信息</h2>
            </div>
            <div className="tabPane-body">
              <div className="myinfo-body">
                <div className="input-body">
                  <label>用户名：</label>{" "}
                  <div className="input-right">
                    {" "}
                    <Input value={this.state.usernameCookies} disabled={true} />
                  </div>
                </div>
                <div className="input-body">
                  <label>个性签名：</label>{" "}
                  <div className="input-right">
                    {" "}
                    <Input
                      onChange={this.handleSignText}
                      value={this.state.signText}
                      placeholder="Basic usage"
                    />
                  </div>
                </div>
                <div className="input-body">
                  <label>性别：</label>{" "}
                  <div className="input-right">
                    <SexSelect defaultValue={this.state.sexValue} />
                  </div>
                </div>
                <div className="input-body">
                  <label>国家/地区：</label>{" "}
                  <div className="input-right">
                    <CountrySelect
                      zoneValue={this.state.zoneValue}
                      list={this.state.zoneOptions}
                    />
                  </div>
                </div>
                <div className="input-body">
                  <label>联系方式：</label>{" "}
                  <div className="input-right">
                    {" "}
                    <Input
                      onChange={this.handleContact}
                      value={this.state.contactValue}
                      placeholder="Basic usage"
                    />
                  </div>
                </div>
                <div className="input-body">
                  <label>出生日期：</label>{" "}
                  <div className="input-right">
                    <YearSelect
                      selectY={this.selectY}
                      y={this.state.y}
                      list={this.state.yearOptions}
                    />
                    <MonthSelect
                      selectM={this.selectM}
                      m={this.state.m}
                      list={this.state.monthOptions}
                    />
                    <DaySelect
                      selectD={this.selectD}
                      d={this.state.d}
                      list={this.state.dayOptions}
                    />
                  </div>
                </div>
                <div className="input-body">
                  <label>修改头像：</label>{" "}
                  <div className="input-right">
                    {this.state.avatarLoading == false &&
                    this.state.avatarHoverId != "" ? (
                      <div
                        style={{ width: "96px", height: "96px" }}
                        className="ant-avatar"
                        onClick={() => this.avatarList()}
                      >
                        <img
                          style={{
                            width: "96px",
                            height: "96px",
                            borderRadius: "96px",
                          }}
                          src={
                            "https://media.91hub-usercontent.work:2096/avatar/" +
                            this.state.avatarHoverId +
                            ".jpg"
                          }
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={() => this.avatarList()}
                        type="primary"
                        style={
                          this.state.isMobile == false
                            ? { width: "80%", margin: "0 auto" }
                            : {}
                        }
                      >
                        点击修改
                      </Button>
                    )}
                  </div>
                </div>
                <Button
                  type="primary"
                  onClick={() => this.updateMyInfo()}
                  style={{ width: "80%", margin: "0 auto" }}
                >
                  提交
                </Button>

                {this.state.avatarLoading == false &&
                this.state.avatarList.length > 0 ? (
                  <AvatarGet
                    avatarCancel={this.avatarCancel.bind(this)}
                    refresh={this.avatarList.bind(this)}
                    avatarClick={this.avatarClick.bind(this)}
                    avatarHoverId={this.state.avatarHoverId}
                    list={this.state.avatarList}
                  />
                ) : null}
              </div>
            </div>
          </TabPane>
          <TabPane tab="稍后观看" key="queue">
            <div className="tabPane-title">
              <h2>
                稍后观看
                <a
                  className="cleanList"
                  onClick={() => (this.deleteItem(0), window.location.reload())}
                >
                  <DeleteOutlined />
                  清空列表
                </a>{" "}
              </h2>
            </div>
            <div className="tabPane-body">
              {this.state.queue.length > 0 ? (
                <ReadItem
                  postidBase={this.postidBase}
                  height={this.state.heightCard}
                  historyLoad={this.historyLoadMore.bind(this)}
                  favoriteLoad={this.favoriteListMore.bind(this)}
                  ticketLoad={this.ticketListMore.bind(this)}
                  type={"queue"}
                  items={this.state.queue}
                  changeHoverId={this.changeHoverId}
                  hoverId={this.state.hoverId}
                  leaveHoverId={this.leaveHoverId}
                  LinkTo={this.goTo.bind(this)}
                  deleteItem={this.deleteItem}
                />
              ) : (
                <Empty description="" />
              )}
            </div>
          </TabPane>
          <TabPane tab="观看历史" key="history">
            <div className="tabPane-title">
              <h2>观看历史</h2>
            </div>
            <div className="tabPane-body">
              <ReadItem
                postidBase={this.postidBase}
                height={this.state.heightCard}
                historyLoad={this.historyLoadMore.bind(this)}
                favoriteLoad={this.favoriteListMore.bind(this)}
                ticketLoad={this.ticketListMore.bind(this)}
                type={"history"}
                items={this.state.historyList}
                changeHoverId={this.changeHoverId}
                hoverId={this.state.hoverId}
                leaveHoverId={this.leaveHoverId}
                LinkTo={this.goTo.bind(this)}
                deleteItem={this.deleteItem}
              />
            </div>
          </TabPane>
          <TabPane tab="我的收藏" key="favorite">
            <div className="tabPane-title">
              <h2>我的收藏</h2>
            </div>
            <div className="tabPane-body">
              <ReadItem
                postidBase={this.postidBase}
                height={this.state.heightCard}
                historyLoad={this.historyLoadMore.bind(this)}
                favoriteLoad={this.favoriteListMore.bind(this)}
                ticketLoad={this.ticketListMore.bind(this)}
                type={"favorite"}
                items={this.state.favoriteList}
                changeHoverId={this.changeHoverId}
                hoverId={this.state.hoverId}
                leaveHoverId={this.leaveHoverId}
                LinkTo={this.goTo.bind(this)}
                deleteItem={this.deleteItem}
              />
            </div>
          </TabPane>
          <TabPane tab="点播记录" key="ticket">
            <div className="tabPane-title">
              <h2>点播记录</h2>
            </div>
            <div className="tabPane-body">
              <ReadItem
                postidBase={this.postidBase}
                height={this.state.heightCard}
                historyLoad={this.historyLoadMore.bind(this)}
                favoriteLoad={this.favoriteListMore.bind(this)}
                ticketLoad={this.ticketListMore.bind(this)}
                type={"ticket"}
                items={this.state.ticketList}
                changeHoverId={this.changeHoverId}
                hoverId={this.state.hoverId}
                leaveHoverId={this.leaveHoverId}
                LinkTo={this.goTo.bind(this)}
                deleteItem={this.deleteItem}
              />
            </div>
          </TabPane>
          <TabPane tab="密码安全" key="password">
            <div className="tabPane-title">
              <h2>密码安全</h2>
            </div>
            <div
              className="tabPane-body password-body"
              style={{ maxWidth: "320px" }}
            >
              <Input
                defaultValue={this.state.usernameCookies}
                disabled={true}
                showCount
                maxLength={14}
                prefix={<UserOutlined />}
              />
              <Input.Password
                onChange={this.handlePassword}
                placeholder="新密码"
                showCount
                maxLength={32}
                prefix={<LockOutlined />}
              />
              <Input.Password
                onChange={this.handlePasswordRe}
                placeholder="重复密码"
                showCount
                maxLength={32}
                prefix={<LockOutlined />}
              />
              <Button
                onClick={() => this.sumbitNewPassword()}
                key={"changePassword"}
                type="primary"
                style={{ width: "80%", margin: "0 auto" }}
              >
                提交
              </Button>
              ;
            </div>
          </TabPane>
          <TabPane tab="登录历史" key="loginHistory">
            <div className="tabPane-title">
              <h2>最近的登录记录</h2>
            </div>
            <div className="tabPane-body">
              {this.state.loginHistory.length == 0 ? (
                <div className="myAccountLoading" key={"historyKey"}>
                  <div className="loading-spinner"></div>
                </div>
              ) : (
                <LoginHistoryGet list={this.state.loginHistory} />
              )}
            </div>
          </TabPane>
          <TabPane tab="推广记录" key="share">
            <div className="tabPane-title">
              <h2>最近的推广记录</h2>
            </div>
            <div className="tabPane-body">
              <p className="shareTips">
                {" "}
                将下方链接(二维码)复制并分享给朋友，或社交媒体，群组，即可获取点播券奖励，成功分享1人奖励5张点播券，（注意：访客停留页面30秒左右才能视为有效，已登录过的用户IP访问无效）奖励不封上限，越多人访问奖励越多。{" "}
              </p>
              {this.state.shareUrl != "" ? (
                <QRCodeSVG value={this.state.shareUrl} />
              ) : (
                ""
              )}
              <br />
              <br />
              <ShareInput url={this.state.shareUrl} />
              <br />
              <br />
              <CopyToClipboard
                text={this.state.shareUrl}
                onCopy={() => (
                  this.setState({ copied: true }), this.success("复制成功")
                )}
              >
                <Button>复制链接到剪切板</Button>
              </CopyToClipboard>
              <br />
              <br />
              <Table
                columns={columns_share}
                dataSource={this.state.shareList}
                pagination={{ pageSize: 12 }}
                rowKey={(columns_share) => columns_share.ip}
              />

              <br />
              <Pagination
                current={this.state.shareCurrent}
                onChange={this.onChangeSharePagination}
                defaultCurrent={1}
                total={this.state.shareTotal}
                showSizeChanger={false}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default MyAccount;
