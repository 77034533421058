import "./App.css";
import React, { useState, useEffect, lazy } from "react";
import {
  Menu,
  Input,
  Drawer,
  Affix,
  Row,
  Col,
  MenuProps,
  Popover,
  BackTop,
  Tabs,
  Empty,
  Avatar,
} from "antd";

import {
  Route,
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  Link,
  Routes,
  Outlet,
} from "react-router-dom";
import {
  AppstoreOutlined,
  UserOutlined,
  ProfileOutlined,
  FolderOpenOutlined,
  SafetyCertificateOutlined,
  FieldTimeOutlined,
  ShareAltOutlined,
  AlignLeftOutlined,
  FolderOutlined,
  MailOutlined,
  HomeOutlined,
  MenuOutlined,
  LoginOutlined,
  BellOutlined,
  CloudUploadOutlined,
  SearchOutlined,
  FolderFilled,
  ArrowRightOutlined,
  QuestionOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  SettingOutlined,
  AlignCenterOutlined,
  LogoutOutlined,
  DeleteOutlined,
  GlobalOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Cookies, useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import md5 from "md5";
import momentZone from "moment-timezone";
import { decode as decode, encode as encode } from "base-64";
import modal from "antd/lib/modal";
import empty from "./pages/assets/empty.png";
import logo from "./pages/assets/xplex.png";
import logo_w from "./pages/assets/l8.png";
import qs from "qs";
import ScrollToTop from "./scrollToTop";
import EmptyPage from "./pages/empty";
import Detail from "./pages/detail";
import Category from "./pages/category";
import Index from "./pages/index";
import Result from "./pages/result";
import Account from "./pages/account";
import MyAccount from "./pages/myAccount";
import Uploads from "./pages/uploads";
import Tags from "./pages/tags";
import Actor from "./pages/actor";
import Product from "./pages/product";

window.title = "Xplex";
window.apiUrl = "https://v3.cdnye.com:2096";
window.cacheUrl = "https://cache.cdnye.com:2096";
window.webmUrl = "https://mediacf.cdnye.com:2096";
window.baseUrl = "cdnye.com:2096";

//const Index = lazy(() => import("./pages/index"));
//const Category = lazy(() => import("./pages/category"));
//const Detail = lazy(() => import("./pages/detail"));
//const Result = lazy(() => import("./pages/result"));
//const Account = lazy(() => import("./pages/account"));
//const MyAccount = lazy(() => import("./pages/myAccount"));
//const Uploads = lazy(() => import("./pages/uploads"));
//const Tags = lazy(() => import("./pages/tags"));
//MailOutlined AppstoreOutlined SettingOutlined
const { Search } = Input;

function App(this: any) {
  const RouteListenerLayout = ({ children }: { children: any | undefined }) => {
    const { pathname } = useLocation();
    const cookie = new Cookies();
    useEffect(() => {
      if (cookie.get("username") != undefined && cookie.get("username") != "") {
        if (localStorage.getItem("index") == undefined) {
          setUsername("username", cookie.get("username"));
          localStorage.setItem("index", "1");
        }
      }

      // send route-change log event to our mongodb collection
    }, [pathname]);

    return children ?? <Outlet />;
  };

  const [width, setWidth] = useState(window.innerWidth);
  let [hasVerticalScrolled, scrollBool] = useState<any>(undefined);

  const [cookies, setCookie, removeCookie] = useCookies(["searchHistory"]);
  const [usernameCookies, setUsername, removeUser] = useCookies(["username"]);
  const [passwordCookies, setPassword, removePass] = useCookies(["password"]);
  const [emailCookies, setEmail, removeEmail] = useCookies(["email"]);
  let [searchExpand, setSearchExpand] = useState<any>(false);
  const [activeKey, setActiveKey] = useState("0");
  const [history, setHistory] = useState<any>(
    localStorage.getItem("localhistory")
  );
  const [watchLater, setWatchLater] = useState<any>(
    localStorage.getItem("localqueue")
  );
  const [checkedList, setCheckedList] = useState<any>(false);
  const [geoIp, setGeoIp] = useState("");
  const [ipaddress, setIpaddress] = useState<any>(
    localStorage.getItem("ipaddress")
  );
  const [link, setLink] = useState([]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    if (hasVerticalScrolled == undefined) {
      window.addEventListener("scroll", bindHandleScroll);
      window.addEventListener("resize", handleWindowSizeChange);

      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }
  }

  function bindHandleScroll(event: any) {
    // 滚动的高度
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop > 10) {
      scrollBool(true);
    } else {
      scrollBool(false);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(window.apiUrl + "/Application/link?from=xplex&sign=" + signGet, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response: { data: any }) {
          var decode5 = keyHash(response.data);
          let reg = new RegExp("\\+", "g"); //g代表全部
          let newMsg = decode5.replace(reg, "  ");
          var result = JSON.parse(newMsg);
          const data = result;

          setLink(data.link);
          console.log(data.link);
        })
        .catch(function (error: any) {});
    }, 2000);

    function cf(url: string, callback: any) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.send();

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          callback(xhr.responseText);
        }
      };
    }
    cf("https://api.ipify.org?format=json", function (data: any) {
      var jsonData = JSON.parse(data);
      console.log(jsonData.ip);
      var ip = jsonData.ip;
      setIpaddress(ip);
      localStorage.setItem("ipaddress", ip);
    });

    axios
      .get(window.apiUrl + "/Application/geoIp?sign=" + signGet)
      .then(function (response: { data: any }) {
        var jsonIp = response.data;
        if (jsonIp.country == "China") {
          setGeoIp("China");
          localStorage.setItem("country", "China");
        } else {
          setGeoIp("OtherPlace");
          localStorage.setItem("country", "OtherPlace");
        }
      })
      .catch(function (error: any) {});
    {
      if (process.env.NODE_ENV != "development") {
        (() => {
          function block() {
            if (
              window.outerHeight - window.innerHeight > 200 ||
              window.outerWidth - window.innerWidth > 200
            ) {
              document.body.innerHTML = "检测到非法调试,请关闭后刷新重试!";
            }
            setInterval(() => {
              (function () {
                return false;
              })
                ["constructor"]("debugger")
                ["call"]();
              console.clear();
            }, 50);
          }
          try {
            block();
          } catch (err) {}
        })();
      }
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query = params.get("ref");
    const cookies = new Cookies();

    if (query != null || cookies.get("ref") != null) {
      cookies.set("ref", query);
      setTimeout(() => {
        axios
          .post(
            window.apiUrl + "/Application/share?sign=" + signGet,
            qs.stringify({
              ref: cookies.get("ref"),
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then(function (response: { data: any }) {})
          .catch(function (error: any) {});
      }, 30000);
    }
  }, []);

  const isMobile = width <= 820;
  let [msgBool, setMsgBool] = useState<boolean>(false);

  let [showPopClass, setClass] = useState("popMenu_hide");
  let [morefloat, setMoreFloat] = useState("left");
  let [visibleGet, setVisible] = useState(false);
  let [searchOverLay, onSearchClickBool] = useState(false);
  let [searchRank, setSearchRank] = useState([]);
  let [historyHover, setHistoryHover] = useState(false);

  let [msgList, setMsgList] = useState<never[]>([]);

  const signGet = md5(
    momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
  );

  const clearCookie = () => {
    removeCookie("searchHistory");
  };

  const onSearch = (value: string) => {
    if (value != null && value != "" && value.length > 0) {
      //cookies.set('name', name, { path: '/' });
      if (cookies.searchHistory == null) {
        let newList: {}[] = [];
        let insert: { keyword: string; date: Date } = {
          keyword: "",
          date: new Date(),
        };
        let date = new Date();
        insert.keyword = value;
        insert.date = date;
        setTimeout(() => {
          newList.push(insert);
        }, 200);
        setTimeout(() => {
          setCookie("searchHistory", JSON.stringify(newList));
          onSearchClickBool(false);
          window.location.href = "/result?keyword=" + value;
        }, 400);
      } else {
        let newList: any = cookies.searchHistory;
        let insert: { keyword: string; date: Date } = {
          keyword: "",
          date: new Date(),
        };
        let date = new Date();
        insert.keyword = value;
        insert.date = date;
        setTimeout(() => {
          if (newList.length < 5) {
            newList.push(insert);
          } else {
            newList[4] = insert;
          }
          setTimeout(() => {
            setCookie("searchHistory", JSON.stringify(newList));
            onSearchClickBool(false);
            window.location.href = "/result?keyword=" + value;
          }, 400);
        }, 200);
      }
    }
  };

  const keyHash = (key: string) => {
    if (key == "400" || key.length == 0) {
      return "";
    } else {
      var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .add(1, "hours")
        .format("YYYY-MM-DD-HH");
      var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .subtract(1, "hours")
        .format("YYYY-MM-DD-HH");

      var add = encode(md5(date1 + "sign91")).replace("=", "");
      var subtract = encode(md5(date2 + "sign91")).replace("=", "");

      var length1 = key.length;
      var length2 = length1 - 20;
      var content1 = key.substring(length2, length1);
      var content2 = key.substring(0, length2);
      ///////////////////////////////////////////
      var keys = encode(signGet).replace("=", "");
      let reg = new RegExp("END", "g"); //g代表全部
      var keyHashFirst = content1.replace(reg, "");
      var keyHash = content2 + keyHashFirst;
      var keyResult = keyHash.replace(keys, "");
      var keyResult1 = keyResult.replace(subtract, "");
      var keyResult2 = keyResult1.replace(add, "");
      return decode(keyResult2.toString());
    }
  };
  const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const hotSearchWord = () => {
    console.log("载入热搜关键词");
    if (searchRank.length == 0) {
      axios
        .get(
          window.apiUrl +
            "/Application/searchQuery?num=28&page=" +
            getRandomInt(1, 8) +
            "&sign=" +
            signGet
        )
        .then(function (response: { data: string }) {
          //
          var decode5 = keyHash(response.data);
          let reg = new RegExp("\\+", "g"); //g代表全部
          let newMsg = decode5.replace(reg, "  ");
          var result = JSON.parse(newMsg);
          const data = result;
          setSearchRank(data);
          setTimeout(() => {
            onSearchClick();
          }, 100);
        })
        .catch(function (error: any) {});
    }
  };

  const onSearchClick = () => {
    console.log(searchRank.length);
    setTimeout(() => {
      const myElement = document.getElementById("search-main-input")!;
      myElement.focus();
    }, 100);
    setTimeout(() => {
      const myElement = document.getElementById("search-main-input")!;
      myElement.focus();
    }, 200);
  };

  const leaveSearchLay = () => {
    onSearchClickBool(false);
  };

  const mouseLeavePop = () => {
    setTimeout(() => {
      setClass("popMenu_hide");
    }, 100);
    //window.location.reload();
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showSearch = () => {
    if (searchExpand == true) {
      setSearchExpand(false);
    } else {
      setSearchExpand(true);
      hotSearchWord();
    }
  };
  const postidBase = (Id: string) => {
    if (Id != null) {
      return encode(Id).replace("=", "_");
    }
  };

  const [top] = useState(0);

  function PcSearch(props: { onSearchClick: any; onSearch: any }) {
    const onSearchClick = props.onSearchClick;
    const onSearch = props.onSearch;
    //const navigate = useNavigate();
    const search = (
      <Search
        id="search-main-input"
        className="searchInput"
        type="text"
        placeholder="搜索一下"
        onSearch={(value: string) => onSearch(value)}
        style={{ width: 420 }}
      />
    );
    return <div onClick={onSearchClick}>{search}</div>;
  }
  let random = Math.floor(Math.random() * 100) + 1;

  const config = {
    title: "是否退出账户？",
    content: <p>清除所有cookies并退出账户</p>,
    cancelText: "取消",
    okText: "确定",
    onOk() {
      removeUser("username");
      removePass("password");
      removeEmail("email");
      setUsername("username", "");
      setPassword("password", "");
      setEmail("email", "");
      document.cookie = `username=;password=;email=;index=;`;
      console.log("已清空cookies");
      localStorage.removeItem("index");
      localStorage.removeItem("username");
      window.location.href = "/index";
      return false;
    },
    onCancel() {},
  };

  const showConfirm = () => {
    modal.confirm(config);
  };

  const openMyMessage = () => {
    if (msgBool == false) {
      setMsgBool(true);
      mouseLeavePop();
      if (msgList.length == 0) {
        axiosGetInbox();
      }
      return false;
    } else {
      setMsgBool(false);
      mouseLeavePop();
    }
  };

  const historyBool = () => {
    setHistoryHover(true);
    if (checkedList == false) {
      setHistory(localStorage.getItem("localhistory"));
      setWatchLater(localStorage.getItem("localqueue"));
      setCheckedList(true);
    }
  };

  const historyBoolFalse = () => {
    setHistoryHover(false);
    setCheckedList(false);
  };
  const onChangeHistory = (key: string) => {
    console.log(key);
  };

  const axiosGetInbox = () => {
    const cookie = new Cookies();
    const username = cookie.get("username");
    const password = cookie.get("password");
    axios
      .post(
        window.apiUrl + "/Application/Inbox?sign=" + signGet,
        qs.stringify({ username: username, password: password }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        //
        var decode5 = keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        setMsgList(msgList.concat(result.list));
      })
      .catch((error: any) => {});
  };

  function MyMessage() {
    const cookie = new Cookies();
    const username = cookie.get("username");
    //const password = cookie.get('password');
    let navigate = useNavigate();

    const goTo = (id: any) => {
      return () => {
        setTimeout(() => {
          navigate("/detail?v=" + postidBase(id), { replace: true });
        }, 100);
      };
    };

    const listItem = msgList.map((item: any, i: any) => (
      <li className="MsgLi" key={item.Id}>
        <div className="MsgLi_Div">
          <p className="MsgLi_p">
            <Avatar size="small" icon={<UserOutlined />} />
            &nbsp;{item.username}
          </p>
          <p className="MsgLi_p">回复了你</p>
          <p className="MsgLi_p">{item.addtime}</p>
        </div>
        <h5 className="MsgLi_content">{item.content}</h5>
        <h5 className="MsgLi_h5" onClick={() => goTo(item.postid)}>
          <Link to={"/detail?v=" + postidBase(item.postid)}>{item.title}</Link>
        </h5>
      </li>
    ));
    return (
      <div className="myMessage">
        {username != "undefined" &&
        username != "" &&
        username != null &&
        username != "guest" &&
        username != undefined &&
        msgList.length > 0 ? (
          <ul className="MsgUl">{listItem}</ul>
        ) : (
          <div className="guest">
            <img className="emptyMsg" src={empty} />
          </div>
        )}
      </div>
    );
  }

  function NemuLeft() {
    const cookie = new Cookies();
    const username = cookie.get("username");
    var pathAll = [
      "../index",
      "../category?id=all",
      "../category?id=1",
      "../category?id=2",
      "../category?id=3",
      "../category?id=4",
      "../category?id=7",
      "../category?id=5",
      "../myAccount?page=myDetail",
      "../myAccount?page=myInfo",
      "../myAccount?page=history",
      "../myAccount?page=queue",
      "../myAccount?page=favorite",
      "../myAccount?page=ticket",
      "../myAccount?page=password",
      "../myAccount?page=loginHistory",
      "../myAccount?page=share",
      "",
      "../account?action=login",
      "../account?action=register",
    ];

    let navigate = useNavigate();
    const onClick: MenuProps["onClick"] = (e) => {
      setActiveKey(e.key);
      console.log("click ", e);

      if (parseInt(e.key) == 20) {
        window.open("https://91hub.cc");
        return false;
      }
      if (parseInt(e.key) == 21) {
        navigate("../i", { replace: true });
        setTimeout(() => {
          navigate("/actor?type=all", { replace: true });
        }, 1);
      }
      if (parseInt(e.key) == 17) {
        showConfirm();
        return false;
      } else {
        navigate("../i", { replace: true });
        setTimeout(() => {
          navigate(pathAll[parseInt(e.key)], { replace: true });
        }, 1);
      }

      //window.location.reload();
    };

    const items: MenuProps["items"] = [
      getItem("Navigation One", "sub1", <MailOutlined />, [
        getItem(
          "Item 1",
          "g1",
          null,
          [getItem("首页", "0", <HomeOutlined />)],
          "group"
        ),
        getItem(
          "Item 2",
          "g2",
          null,
          [
            getItem("最新", "1", <FolderOutlined />),
            getItem("国产频道", "2", <FolderOutlined />),
            getItem("日韩频道", "3", <FolderOutlined />),
            getItem("欧美频道", "4", <FolderOutlined />),
            getItem("动漫频道", "5", <FolderOutlined />),
            getItem("直播频道", "6", <FolderOutlined />),
            getItem("小众频道", "7", <FolderOutlined />),
          ],
          "group"
        ),
        getItem(
          "Item 6",
          "g6",
          null,
          [getItem("AV女优", "21", <UserOutlined />)],
          "group"
        ),
        getItem(
          "Item 5",
          "g5",
          null,
          [getItem("91HUB 国际版", "20", <GlobalOutlined />)],
          "group"
        ),
        username != null && username.length > 0
          ? getItem(
              "Item 3",
              "g3",
              null,
              [
                getItem("我的账户", "8", <UserOutlined />),
                getItem("我的信息", "9", <ProfileOutlined />),
                getItem("观看历史", "10", <FolderOpenOutlined />),
                getItem("稍后观看", "11", <FolderOpenOutlined />),
                getItem("我的收藏", "12", <FolderOpenOutlined />),
                getItem("点播记录", "13", <FolderOpenOutlined />),
                getItem("密码安全", "14", <SafetyCertificateOutlined />),
                getItem("登录历史", "15", <FieldTimeOutlined />),
                getItem("推广记录", "16", <ShareAltOutlined />),
              ],
              "group"
            )
          : null,

        username != null && username.length > 0
          ? getItem(
              "Item 4",
              "g4",
              null,
              [getItem("登出账号", "17", <AlignLeftOutlined />)],
              "group"
            )
          : getItem(
              "Item 4",
              "g4",
              null,
              [
                getItem("登录账号", "18", <LoginOutlined />),
                getItem("注册账号", "19", <LoginOutlined />),
              ],
              "group"
            ),
      ]),
    ];
    return (
      <Menu
        onClick={onClick}
        style={{ width: "100%" }}
        defaultSelectedKeys={[activeKey]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    );
  }

  function Headers() {
    const cookie = new Cookies();
    const username = cookie.get("username");
    const tongzhiContent = (
      <a onClick={() => openMyMessage()} style={{ color: "#5f6368" }}>
        通知
      </a>
    );
    const gengduoContent = (
      <ul>
        <li>
          <a href="https://fby.xplex.cc:2096">地址发布页</a>
        </li>
        <li>
          <a href="https://91hub.cc">91HUB</a>
        </li>
      </ul>
    );
    const wode = (
      <div>
        <ul>
          <li>
            <a target="_blank" href="/myAccount?page=myDetail">
              我的账户
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=myInfo">
              我的信息
            </a>
          </li>
          <li>
            <a target="_blank" href="#">
              上传视频
            </a>
          </li>
          <li>
            <a target="_blank" href="#">
              我的消息
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=history">
              观看历史
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=queue">
              稍后观看
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=favorite">
              我的收藏
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=ticket">
              点播记录
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=password">
              密码安全
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=loginHistory">
              登录历史
            </a>
          </li>
          <li>
            <a target="_blank" href="/myAccount?page=share">
              访问推广
            </a>
          </li>
          <li
            onClick={() => {
              showConfirm();
            }}
          >
            {" "}
            <a>登出注销</a>{" "}
          </li>
        </ul>
      </div>
    );
    const denglu = (
      <div>
        <ul>
          <li>
            <a href="../account?action=login">登录账户</a>
          </li>
          <li>
            <a href="../account?action=register">注册账户</a>{" "}
          </li>
          <li>
            <a href="../account?action=forget">找回密码</a>{" "}
          </li>
        </ul>
      </div>
    );

    return (
      <Row>
        {isMobile == false ? (
          <Col span={4}>
            <div className="fixedLeft">
              <img className="logoTop" src={logo}></img>
              <NemuLeft />
              {username != null && username.length > 0 ? null : (
                <div className="leftFooter">
                  <div>
                    友情链接：
                    {link.length == 0 ? (
                      <a style={{ color: "#505050", marginRight: "10px" }}>
                        载入中
                      </a>
                    ) : null}
                    {link != undefined
                      ? link.map(
                          (
                            item: {
                              linkname: string;
                              url: string;
                            },
                            i: number
                          ) => (
                            <a
                              target="_blank"
                              href={item.url}
                              style={{ color: "#505050", marginRight: "10px" }}
                            >
                              {item.linkname}
                            </a>
                          )
                        )
                      : null}
                  </div>
                  <ExclamationCircleOutlined />
                  在使用我们的服务前，请您遵守当地法律法规，并确认您已年满18周岁。
                  <br />
                  联络我们：xplexnet@gmail.com
                  <br />
                  ©2024 All rights reserved
                </div>
              )}
            </div>
          </Col>
        ) : (
          <div></div>
        )}

        <Col span={isMobile == false ? 20 : 24}>
          <div className="parentPage">
            <ScrollToTop />
            <div className="rn-header">
              {isMobile === false ? (
                <Affix offsetTop={top}>
                  <div className="nav">
                    <ul className="menu">
                      <li className="search">
                        <div className="searchInput">
                          <PcSearch
                            onSearchClick={() => (
                              onSearchClick(),
                              hotSearchWord(),
                              onSearchClickBool(true)
                            )}
                            onSearch={onSearch}
                          />
                          {searchOverLay == true ? (
                            <div
                              className="searchOverLay"
                              onMouseLeave={leaveSearchLay}
                            >
                              <h5>
                                历史搜索
                                <a onClick={clearCookie}>
                                  <DeleteOutlined />
                                  清空
                                </a>
                              </h5>
                              <ul>
                                {cookies.searchHistory != null ? (
                                  cookies.searchHistory
                                    .slice(0, 5)
                                    .reverse()
                                    .map(
                                      (
                                        item: {
                                          keyword: string;
                                          date: React.Key | null | undefined;
                                        },
                                        i: number
                                      ) => (
                                        <li
                                          key={
                                            item.date?.toString() +
                                            random.toString()
                                          }
                                        >
                                          <a
                                            href={
                                              "/result?keyword=" + item.keyword
                                            }
                                            key={item.date}
                                          >
                                            {item.keyword}
                                          </a>
                                        </li>
                                      )
                                    )
                                ) : (
                                  <div>
                                    <p>暂无历史记录</p>
                                  </div>
                                )}
                              </ul>
                              <h5>热门搜索</h5>
                              <ul>
                                {searchRank != undefined ? (
                                  searchRank
                                    .reverse()
                                    .slice(0, 5)
                                    .map(
                                      (
                                        item: {
                                          keyword: string;
                                          points: string;
                                        },
                                        i: number
                                      ) => (
                                        <li key={item.keyword + random}>
                                          <span
                                            className={
                                              "rank" +
                                              String(parseInt(i.toString()) + 1)
                                            }
                                          >
                                            {parseInt(i.toString()) + 1}
                                          </span>
                                          {i < 5 ? (
                                            <a
                                              href={
                                                "/result?keyword=" +
                                                item.keyword
                                              }
                                              key={item.points}
                                            >
                                              {item.keyword}
                                            </a>
                                          ) : (
                                            ""
                                          )}{" "}
                                        </li>
                                      )
                                    )
                                ) : (
                                  <div>
                                    <p>暂无热门记录</p>
                                  </div>
                                )}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                      <li className="message">
                        <div className="index">
                          <Popover
                            placement="bottom"
                            title={undefined}
                            //content={tongzhiContent}
                            trigger="click"
                          >
                            <a onClick={() => openMyMessage()}>
                              {" "}
                              <BellOutlined />
                              消息
                            </a>
                          </Popover>
                        </div>
                      </li>
                      <li onMouseEnter={mouseLeavePop} className="uploads">
                        <div className="index" onMouseLeave={mouseLeavePop}>
                          <a href="/uploads">
                            <CloudUploadOutlined />
                            上传
                          </a>
                        </div>
                      </li>
                      <li className="more">
                        <div className="category">
                          <Popover
                            placement="bottom"
                            title={undefined}
                            content={gengduoContent}
                            trigger="click"
                          >
                            <a href="#">
                              {" "}
                              <AppstoreOutlined />
                              更多
                            </a>
                          </Popover>
                        </div>
                      </li>
                      <li
                        className="localhistory"
                        onMouseOver={historyBool}
                        onMouseLeave={historyBoolFalse}
                        style={{
                          display: "block",
                          width: "100px",
                          textAlign: "left",
                        }}
                      >
                        <a>
                          <ClockCircleOutlined /> 历史
                        </a>
                        <div
                          className="historyFixed"
                          style={
                            historyHover == true
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <Tabs
                            defaultActiveKey="1"
                            onChange={onChangeHistory}
                            items={[
                              {
                                label: `历史记录`,
                                key: "1",
                                children: (
                                  <ul>
                                    {history != null &&
                                    history != "" &&
                                    history.length > 0 ? (
                                      JSON.parse(history)
                                        .reverse()
                                        .map(
                                          (
                                            item: {
                                              username: string;
                                              Id: number;
                                              title: string;
                                              poster: string;
                                            },
                                            i: number
                                          ) =>
                                            item.poster != undefined ? (
                                              <li
                                                key={
                                                  item.title?.toString() +
                                                  random.toString()
                                                }
                                              >
                                                <div className="leftThumbnail">
                                                  <a
                                                    href={
                                                      "/detail?v=" +
                                                      postidBase(
                                                        item.Id.toString()
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        window.cacheUrl +
                                                        "/" +
                                                        item.poster +
                                                        "/thumb.jpg"
                                                      }
                                                    ></img>
                                                  </a>
                                                </div>
                                                <div className="rightContent">
                                                  <a
                                                    href={
                                                      "/detail?v=" +
                                                      postidBase(
                                                        item.Id.toString()
                                                      )
                                                    }
                                                  >
                                                    {item.title}
                                                  </a>
                                                  <a className="channelName">
                                                    {item.username}
                                                  </a>
                                                </div>
                                              </li>
                                            ) : null
                                        )
                                    ) : (
                                      <Empty description="" />
                                    )}
                                  </ul>
                                ),
                              },
                              {
                                label: `稍后观看`,
                                key: "2",
                                children: (
                                  <ul>
                                    {watchLater != null &&
                                    watchLater != "" &&
                                    watchLater.length > 0 ? (
                                      JSON.parse(watchLater)
                                        .reverse()
                                        .map(
                                          (
                                            item: {
                                              username: string;
                                              Id: number;
                                              title: string;
                                              poster: string;
                                            },
                                            i: number
                                          ) =>
                                            item.poster != undefined ? (
                                              <li
                                                key={
                                                  item.title?.toString() +
                                                  random.toString()
                                                }
                                              >
                                                <div className="leftThumbnail">
                                                  <a
                                                    href={
                                                      "/detail?v=" +
                                                      postidBase(
                                                        item.Id.toString()
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        window.cacheUrl +
                                                        "/" +
                                                        item.poster +
                                                        "/thumb.jpg"
                                                      }
                                                    ></img>
                                                  </a>
                                                </div>
                                                <div className="rightContent">
                                                  <a
                                                    href={
                                                      "/detail?v=" +
                                                      postidBase(
                                                        item.Id.toString()
                                                      )
                                                    }
                                                  >
                                                    {item.title}
                                                  </a>
                                                  <a className="channelName">
                                                    {item.username}
                                                  </a>
                                                </div>
                                              </li>
                                            ) : null
                                        )
                                    ) : (
                                      <Empty description="" />
                                    )}
                                  </ul>
                                ),
                              },
                            ]}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </Affix>
              ) : (
                <div>
                  <div className="navMobile">
                    <ul className="navLeft">
                      <Link to={"/index"} className="logo">
                        <img className="logoMobileTop" src={logo_w}></img>
                      </Link>
                    </ul>
                    <ul className="navRight">
                      <li className="search">
                        <a onClick={showSearch}>
                          <SearchOutlined />
                        </a>
                      </li>
                      <li className="more">
                        <a onClick={showDrawer}>
                          <MenuOutlined />
                        </a>
                      </li>
                    </ul>
                    <>
                      <Drawer
                        className="left-mobile-drawer"
                        placement="left"
                        closable={false}
                        onClose={onClose}
                        visible={visibleGet}
                        key={morefloat}
                        width="50vw"
                      >
                        <ul>
                          <li>
                            <a>
                              <HomeOutlined />
                              首页
                            </a>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a href="/category?id=all">
                              <FolderFilled style={{ color: "#ffcc66" }} />
                              最新
                            </a>
                          </li>
                          <li>
                            <a href="/category?id=1">
                              <FolderFilled style={{ color: "#ffcc66" }} />
                              国产
                            </a>
                          </li>
                          <li>
                            <a href="/category?id=2">
                              <FolderFilled style={{ color: "#ffcc66" }} />
                              日韩
                            </a>
                          </li>
                          <li>
                            <a href="/category?id=3">
                              <FolderFilled style={{ color: "#ffcc66" }} />
                              欧美
                            </a>
                          </li>
                          <li>
                            <a href="/category?id=4">
                              <FolderFilled style={{ color: "#ffcc66" }} />
                              动漫
                            </a>
                          </li>
                          <li>
                            <a href="/category?id=7">
                              <FolderFilled style={{ color: "#ffcc66" }} />
                              直播
                            </a>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a href="/actor?type=all">
                              <UserOutlined />
                              AV女优
                            </a>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a href="https://91hub.cc">
                              <GlobalOutlined />
                              91HUB 国际版
                            </a>
                          </li>
                        </ul>
                        {username != null && username.length > 0 ? (
                          <ul>
                            <li>
                              <a href="/myAccount?page=myDetail">
                                <TeamOutlined />
                                我的账户
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=myInfo">
                                <ProfileOutlined />
                                我的信息
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=queue">
                                <ClockCircleOutlined />
                                稍后观看
                              </a>
                            </li>
                            <li>
                              <a href="/uploads">
                                <CloudUploadOutlined />
                                上传视频
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=myDetail">
                                <BellOutlined />
                                我的消息
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=history">
                                <AlignLeftOutlined />
                                观看历史
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=favorite">
                                <FolderOpenOutlined />
                                我的收藏
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=ticket">
                                <FileDoneOutlined />
                                点播记录
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=password">
                                <SettingOutlined />
                                密码安全
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=loginHistory">
                                <AlignCenterOutlined />
                                登录历史
                              </a>
                            </li>
                            <li>
                              <a href="/myAccount?page=share">
                                <ShareAltOutlined />
                                访问推广
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => {
                                  showConfirm();
                                }}
                              >
                                <LogoutOutlined />
                                登出注销
                              </a>
                            </li>
                          </ul>
                        ) : (
                          <ul>
                            <li>
                              <a href="/account?action=login">
                                <LoginOutlined />
                                登录
                              </a>
                            </li>
                            <li>
                              <a href="/account?action=register">
                                <ArrowRightOutlined />
                                注册
                              </a>
                            </li>
                            <li>
                              <a href="/account?action=forget">
                                <QuestionOutlined />
                                找回密码
                              </a>
                            </li>
                          </ul>
                        )}
                      </Drawer>
                    </>
                  </div>
                  {searchExpand == true ? (
                    <div
                      className="navSearch mobileSearch"
                      style={{ position: "absolute" }}
                    >
                      <Search
                        placeholder="搜索一些你感兴趣的吧"
                        onSearch={onSearch}
                        enterButton
                      />

                      <div
                        className="searchHistory-mobile"
                        style={{ position: "relative" }}
                      >
                        <h5>
                          历史搜索
                          <a onClick={clearCookie}>
                            <DeleteOutlined />
                            清空
                          </a>
                        </h5>
                        {cookies.searchHistory != null ? (
                          <ul style={{ position: "relative" }}>
                            {cookies.searchHistory != null
                              ? cookies.searchHistory
                                  .slice(0, 5)
                                  .reverse()
                                  .map(
                                    (
                                      item: {
                                        keyword: string;
                                        date: React.Key | null | undefined;
                                      },
                                      i: number
                                    ) => (
                                      <li
                                        key={
                                          item.date?.toString() +
                                          random.toString()
                                        }
                                      >
                                        <a
                                          className="historyWordA"
                                          href={
                                            "/result?keyword=" + item.keyword
                                          }
                                          key={item.date}
                                        >
                                          {item.keyword}
                                        </a>
                                      </li>
                                    )
                                  )
                              : null}
                          </ul>
                        ) : (
                          <p>暂无搜索记录</p>
                        )}
                      </div>
                      <div
                        className="searchHot-mobile"
                        style={{ position: "relative" }}
                      >
                        <div>
                          <h5>热搜推荐</h5>
                          <ul>
                            {searchRank != undefined ? (
                              searchRank.reverse().map(
                                (
                                  item: {
                                    keyword: string;
                                    points: string;
                                  },
                                  i: number
                                ) => (
                                  <li key={item.keyword + random}>
                                    {
                                      <a
                                        className="searchWordA"
                                        href={"/result?keyword=" + item.keyword}
                                        key={item.points}
                                      >
                                        {item.keyword}
                                      </a>
                                    }{" "}
                                  </li>
                                )
                              )
                            ) : (
                              <div>
                                <p>暂无热门记录</p>
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
              <Drawer
                title="我的通知"
                placement="right"
                onClose={openMyMessage}
                visible={msgBool}
              >
                <MyMessage />
              </Drawer>
            </div>
          </div>
        </Col>
        {searchExpand == true ? (
          <div className="bg-mask" onClick={showSearch}></div>
        ) : (
          ""
        )}
        <BackTop />
      </Row>
    );
  }

  return (
    <div className={isMobile == true ? "isMobile" : ""}>
      <Router>
        <Headers />
        {isMobile == false ? <Col span={4}></Col> : <div></div>}

        <Col
          span={isMobile == false ? 20 : 24}
          className={isMobile == false ? "rightContent" : ""}
        >
          <Routes>
            <Route path="/i" element={<EmptyPage />} />
            <Route path="/" element={<Index />} />
            <Route
              path="/Index"
              element={
                //<RouteListenerLayout>//
                <Index />
                //</RouteListenerLayout>//
              }
            />
            <Route path="/Detail" element={<Detail />} />
            <Route
              path="/Category"
              element={
                //<RouteListenerLayout>
                <Category />
                //</RouteListenerLayout>
              }
            />
            <Route path="/Result" element={<Result />} />
            <Route path="/Account" element={<Account />} />
            <Route path="/MyAccount" element={<MyAccount />} />
            <Route path="/Uploads" element={<Uploads />} />
            <Route path="/Tags" element={<Tags />} />
            <Route path="/Product" element={<Product />} />
            <Route path="/Actor" element={<Actor />} />
          </Routes>
        </Col>
      </Router>
      {isMobile == true ? (
        <footer>
          {" "}
          <div>
            友情链接：
            {link.length == 0 ? (
              <a style={{ color: "#505050", marginRight: "10px" }}>载入中</a>
            ) : null}
            {link != undefined
              ? link.map(
                  (
                    item: {
                      linkname: string;
                      url: string;
                    },
                    i: number
                  ) => (
                    <a
                      target="_blank"
                      href={item.url}
                      style={{ color: "#505050", marginRight: "10px" }}
                    >
                      {item.linkname}
                    </a>
                  )
                )
              : null}
          </div>
          <ExclamationCircleOutlined />
          在使用我们的服务前，请您遵守当地法律法规，并确认您已年满18周岁。
          <br />
          联络我们：xplexnet@gmail.com
          <br />
          ©2024 All rights reserved
        </footer>
      ) : null}
    </div>
  );
}

export default App;
