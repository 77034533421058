import React from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Input,
  Select,
  UploadProps,
  message,
  Upload,
  Button,
  Collapse,
  Pagination,
  Progress,
  Switch,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import md5 from "md5";
import momentZone from "moment-timezone";
import { encode, decode } from "js-base64";
import "../uploads.css";
import {
  UploadOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import qs from "qs";
import { Cookies } from "react-cookie";
import waitjpg from "./assets/wait.jpg";
import { FileUploader } from "react-drag-drop-files";
import TextArea from "antd/lib/input/TextArea";
import ReactGA from "react-ga4";

ReactGA.initialize("G-883ML6DGJC");
interface props {}
interface stateType {
  isMobile: boolean;
  signGet: string;
  width: number;
  categoryOptions: {}[];
  mleft: boolean;
  mright: boolean;
  videolist: never[];
  total: number;
  totalPage: number;
  mp4file: File | null;
  mp4filename: string;
  thumbfile: File | null;
  thumbfilename: string;
  uploading: boolean;
  ifok: string;
  uploadStatus: number;
  titleActive: string;
  categoryActive: string;
  switchDesc: boolean;
  switchThumb: boolean;
  descString: string;
  currentPage: number;
}

class Uploads extends React.PureComponent<props, stateType> {
  constructor(props: props | Readonly<props>) {
    super(props);
    console.log("running");
    var date = md5(
      momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
    );
    this.state = {
      isMobile: window.innerWidth <= 820,
      signGet: date,
      width: window.innerWidth,
      categoryOptions: [
        {
          value: "3",
          label: "国产",
        },
        {
          value: "4",
          label: "日韩",
        },
        {
          value: "2",
          label: "欧美",
        },
        {
          value: "6",
          label: "动漫",
        },
        {
          value: "7",
          label: "直播",
        },
      ],
      mleft: true,
      mright: false,
      videolist: [],
      total: 0,
      totalPage: 0,
      mp4file: null,
      mp4filename: "",
      thumbfile: null,
      thumbfilename: "",
      uploading: false,
      ifok: "",
      uploadStatus: 0,
      titleActive: "",
      categoryActive: "3",
      switchDesc: false,
      switchThumb: false,
      descString: "",
      currentPage: 1,
    };
  }

  componentDidMount() {
    const cookie = new Cookies();
    const username = cookie.get("username");
    const password = cookie.get("password");

    if (username == null || username == undefined || username.length < 2) {
      window.location.href = "/index";
    }
    if (password == null || password == undefined || password.length < 2) {
      window.location.href = "/index";
    }

    if (this.state.mleft == true) {
      this.axiosGet("1", "0");
    } else {
      this.axiosGet("1", "1");
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    document.title = "上传视频 - " + window.title;
    ReactGA.send({
      hitType: "pageview",
      page: "/uploads",
      title: "上传视频 - " + window.title,
    });
  }

  handleWindowSizeChange = () => {
    return () => {
      this.setState({ width: window.innerWidth });
    };
  };

  keyHash = (key: string) => {
    if (key == "400" || key.length == 0) {
      return "";
    } else {
      var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .add(1, "hours")
        .format("YYYY-MM-DD-HH");
      var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .subtract(1, "hours")
        .format("YYYY-MM-DD-HH");

      var add = encode(md5(date1 + "sign91")).replace("=", "");
      var subtract = encode(md5(date2 + "sign91")).replace("=", "");

      var length1 = key.length;
      var length2 = length1 - 20;
      var content1 = key.substring(length2, length1);
      var content2 = key.substring(0, length2);
      ///////////////////////////////////////////
      var keys = encode(this.state.signGet).replace("=", "");
      let reg = new RegExp("END", "g"); //g代表全部
      var keyHashFirst = content1.replace(reg, "");
      var keyHash = content2 + keyHashFirst;
      var keyResult = keyHash.replace(keys, "");
      var keyResult1 = keyResult.replace(subtract, "");
      var keyResult2 = keyResult1.replace(add, "");
      return decode(keyResult2.toString());
    }
  };
  formatDate(value: any) {
    const s = 1000,
      m = 60 * s,
      h = 60 * m,
      d = 24 * h,
      mo = 30 * d,
      y = 365 * d;

    const fdate = [
      {
        time: y,
        text: "年",
      },
      {
        time: mo,
        text: "月",
      },
      {
        time: d,
        text: "天",
      },
      {
        time: h,
        text: "小时",
      },
      {
        time: m,
        text: "分钟",
      },
      {
        time: s,
        text: "秒",
      },
    ];
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      //return "ios";
      var newDate = new Date();
      var getDate = new Date(value.replace(/-/g, "/")).getTime();
      var seconds = Math.floor(
        (parseInt(newDate.toString()) - parseInt(getDate.toString())) / 1000
      );
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + "年前";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + "月前";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + "天前";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + "小时前";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + "分钟前";
      }
      return Math.floor(seconds) + "秒前";
    } else {
      //return "andriod";
      // s m h d mo y
      // 计算出时间差
      const time = Date.now() - parseInt(new Date(value).valueOf().toString());
      for (let i = 0; i < fdate.length; i++) {
        const t = time / fdate[i].time;
        if (t >= 1) {
          return Math.floor(t) + fdate[i].text + "前";
        }
      }
    }
  }

  success = (msg: any) => {
    message.success(msg);
  };

  error = (msg: any) => {
    message.error(msg);
  };

  warning = (msg: any) => {
    message.warning(msg);
  };

  axiosGet = (page: any, type: any) => {
    const cookie = new Cookies();
    const username = cookie.get("username");
    const password = cookie.get("password");

    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({
      videolist: [],
    });
    axios
      .post(
        "https://uploads.cdnye.com/pushDetail?page=" +
          page +
          "&sign=" +
          this.state.signGet,
        qs.stringify({ username: username, password: password, type: type }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        //
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        console.log(data.list);
        this.setState({
          //videolist: _this.state.videolist.concat(data),
          videolist: data.list,
          //videolist: data,
        });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.axiosGetPagination(type);
      })
      .catch(function (error: any) {});
  };

  axiosGetPagination(type: any) {
    const cookie = new Cookies();
    const username = cookie.get("username");
    const password = cookie.get("password");

    axios
      .post(
        "https://uploads.cdnye.com/pagination?sign=" + this.state.signGet,
        qs.stringify({ username: username, password: password, type: type }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response: { data: any }) => {
        this.setState({
          //videolist: _this.state.videolist.concat(data),
          total: response.data.total,
          totalPage: response.data.page,
          //videolist: data,
        });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch(function (error: any) {});
  }
  onChangePagination = (value: any) => {
    this.setState({
      currentPage: value,
    });
    if (this.state.mleft == true) {
      this.axiosGet(value, "0");
    } else {
      this.axiosGet(value, "1");
    }
  };

  handleUploads = (file: any) => {
    if (file == undefined || file == null) {
      return false;
    }
    console.log(file);
    console.log(file[0].name);
    var mbSize = file[0].size / 1024000;
    if (file[0].type != "video/mp4") {
      this.error("视频文件类型不符合要求");
      return false;
    }
    console.log(mbSize);
    if (mbSize > 1024) {
      this.error("视频体积超出限制，请重试");
      return false;
    }
    this.setState({
      mp4file: file[0],
      mp4filename: file[0].name,
    });
  };
  handleThumbnail = (file: any) => {
    if (file == undefined || file == null) {
      return false;
    }
    console.log(file.target.files[0]);
    var file = file.target.files;
    var mbSize = file[0].size / 1024000;
    if (file[0].type != "image/jpeg") {
      this.error("缩略图文件类型不符合要求");
      return false;
    }
    console.log(mbSize);
    if (mbSize > 2) {
      this.error("视频体积超出限制，请重试");
      return false;
    }
    this.setState({
      thumbfile: file[0],
      thumbfilename: file[0].name,
    });
  };

  randomString(length: number) {
    var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += str[Math.floor(Math.random() * str.length)];
    return result;
  }

  sumbitUploads = async () => {
    if (
      this.state.categoryActive == null ||
      this.state.categoryActive == undefined
    ) {
      this.error("分类还未勾选");
      return false;
    }
    if (this.state.titleActive == null || this.state.titleActive == undefined) {
      this.error("还未输入标题");
      return false;
    } else {
      if (
        this.state.titleActive.length < 3 ||
        this.state.titleActive.length > 100
      ) {
        this.error("标题字数不符合要求");
        return false;
      }
    }
    if (this.state.mp4file == null || this.state.mp4file == undefined) {
      this.error("视频文件为空，请重试");
      return false;
    }

    const cookie = new Cookies();
    const username = cookie.get("username");
    const password = cookie.get("password");
    var taskId = this.randomString(6);

    this.setState({ uploading: true });
    var size = 0;
    if (this.state.mp4file != null) {
      size = this.state.mp4file.size;
    }
    if (this.state.mp4file != null) {
      var shardSize = 2 * 1024 * 1024; //以2MB为一个分片
      var shardCount = Math.ceil(size / shardSize); //总片数

      for (var i = 0; i < shardCount; ++i) {
        var total = shardCount - 1;
        this.setState({ uploadStatus: (i / total) * 100 });
        console.log(this.state.uploadStatus);
        //计算每一片的起始与结束位置
        var start = i * shardSize;
        var end = Math.min(size, start + shardSize);
        var form = new FormData();

        var data = this.state.mp4file.slice(start, end);
        console.log(data);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        let fd = new FormData();

        fd.append("username", username);
        fd.append("password", password);
        fd.append("file", data);
        fd.append("n", i.toString());
        fd.append("total", total.toString());
        fd.append("task", taskId.toString());
        await axios
          .post(
            "https://uploads.cdnye.com/chunk?sign=" + this.state.signGet,
            fd,
            config
          )
          .then(() => {})
          .catch(() => {
            fd.append("username", username);
            fd.append("password", password);
            fd.append("file", data);
            fd.append("n", i.toString());
            fd.append("total", total.toString());
            fd.append("task", taskId);
            axios
              .post(
                "https://uploads.cdnye.com/chunk?sign=" + this.state.signGet,
                fd,
                config
              )
              .then(() => {});
          });
        if (i == total) {
          console.log(this.state.descString);
          setTimeout(() => {
            let fd1 = new FormData();
            fd1.append("username", username);
            fd1.append("password", password);
            fd1.append("title", this.state.titleActive);
            fd1.append("category", this.state.categoryActive);
            fd1.append(
              "description",
              this.state.descString != null ? this.state.descString : ""
            );
            fd1.append(
              "thumbnail",
              this.state.thumbfile != null ? this.state.thumbfile : ""
            );
            fd1.append("task", taskId);
            console.log(fd1);
            axios
              .post(
                "https://uploads.cdnye.com/upload?sign=" + this.state.signGet,
                fd1,
                config
              )
              .then((res: { data: { msg: any } }) => {
                console.log(res);
                this.warning(res.data.msg);
                setTimeout(() => {
                  window.location.reload();
                }, 200);
              });
            this.setState({ ifok: "success" });
          }, 1000);
          setTimeout(() => {
            this.setState({ uploading: false });
            this.warning("视频即将上传完成，请稍等");
            //
          }, 2000);
        }
      }
    }
  };
  handleTitle = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ titleActive: value.target.value });
  };
  setCategory = (value: any) => {
    console.log(value);
    this.setState({ categoryActive: value });
  };
  handleDesc = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ descString: value.target.value });
  };

  render() {
    const { TabPane } = Tabs;
    const { Option } = Select;
    const { Dragger } = Upload;
    const { Panel } = Collapse;
    const fileTypes = ["MP4"];

    const props: UploadProps = {
      name: "file",
      accept: "mp4",
      multiple: true,
      //action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onChange(info) {
        const { status } = info.file;
      },
      onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
      },
    };

    function SelectCategory(props: {
      items: any;
      setCategory: any;
      defaultValue: any;
    }) {
      const items = props.items;
      const setCategory = props.setCategory;
      const defaultValue = props.defaultValue;

      const listItem = items.map((item: any, i: any) => (
        <Option key={item.value + i.toString} value={item.value}>
          {item.label}
        </Option>
      ));
      return (
        <Select defaultValue={defaultValue} onChange={setCategory}>
          {listItem}
        </Select>
      );
    }

    function ListItems(props: { items: any; height: any; formatDate: any }) {
      //const alertpop = alertpop()
      const items = props.items;

      const formatDate = props.formatDate;
      let random = Math.floor(Math.random() * 100) + 1;
      //const hoverId = props.hoverId
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      //style={{ height: height, width: "100%" }}
      const listItem = items.map((item: any, i: any) => (
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} key={item.Id}>
          <Card
            style={
              window.innerWidth < 750 ? { width: "40vw" } : { width: "210px" }
            }
            className="search-col-left"
            hoverable
            cover={
              <Link to="/detail">
                <div className="videoPoster">
                  <div className="videoCard">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                </div>
              </Link>
            }
          >
            {" "}
          </Card>
          <div
            className="search-col-right"
            style={
              window.innerWidth < 750 ? { width: "50vw" } : { width: "auto" }
            }
          >
            <Link to="/detail">
              <p className="videoName">{item.pushTitle}</p>
              <div className="videoInfoMore">
                <span className="smalltext">视频分类：{item.categoryName}</span>
                <span className="smalltext">
                  上传于：{formatDate(item.pushTime)}
                </span>
              </div>
            </Link>
          </div>
        </Col>
      ));

      return <Row>{listItem}</Row>;
    }

    return (
      <div
        id="uploadsPage"
        className={this.state.isMobile == true ? "uploadsMobile" : ""}
      >
        <div className="uploads-header">
          <h1>
            <UploadOutlined />
            上传投稿
          </h1>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="上传" key="1">
            <h1 style={{ textAlign: "center", margin: "30px 0px" }}>
              填写视频信息
            </h1>
            <Input.Group compact>
              <SelectCategory
                items={this.state.categoryOptions}
                setCategory={this.setCategory}
                defaultValue={this.state.categoryActive}
              />
              <Input
                disabled={this.state.uploading == true ? true : false}
                defaultValue=""
                onChange={this.handleTitle}
              />
            </Input.Group>
            <br />
            <br />
            <div className="video-description">
              视频简介：
              <Switch
                defaultChecked={this.state.switchDesc}
                onChange={() =>
                  this.setState({
                    switchDesc: this.state.switchDesc == true ? false : true,
                  })
                }
              />
              {this.state.switchDesc == true ? (
                <div>
                  {" "}
                  <br />
                  <TextArea
                    disabled={this.state.uploading == true ? true : false}
                    rows={4}
                    onChange={this.handleDesc}
                    placeholder="在这里输入视频简介"
                    maxLength={200}
                  />{" "}
                </div>
              ) : (
                <p>如未勾选，则只显示视频标题</p>
              )}
            </div>
            <div className="video-thumbnail">
              自定义缩略图：
              <Switch
                defaultChecked={this.state.switchThumb}
                onChange={() =>
                  this.setState({
                    switchThumb: this.state.switchThumb == true ? false : true,
                  })
                }
              />
              {this.state.switchThumb == true ? (
                <div className="thumbnail-input">
                  <input
                    onChange={(event) => {
                      this.handleThumbnail(event);
                    }}
                    type="file"
                    name="jpg"
                    accept=".jpg;"
                  />{" "}
                </div>
              ) : (
                <p>如未勾选，审核人员将挑选最佳视频画面作为封面</p>
              )}
            </div>
            <br />
            <br />
            <FileUploader
              classes="uploadsMyVideo"
              multiple={true}
              handleChange={this.handleUploads}
              name="file"
              types={fileTypes}
              children={
                <div>
                  {this.state.mp4file != null &&
                  this.state.uploading == false ? (
                    <div className="selectFile">
                      <h3>已选择： </h3>
                      <h5>
                        <FileDoneOutlined />
                        {this.state.mp4filename}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.mp4file == null ? (
                    <div className="ant-upload-drag-container">
                      <CloudUploadOutlined
                        size={128}
                        style={{
                          color: "#1890ff",
                          width: "128px",
                          height: "128px",
                        }}
                      />
                      <p className="ant-upload-text">
                        拖动到此处 / 或点击上传视频
                      </p>
                      <p className="ant-upload-hint">
                        视频mime类型只接受mp4(h264，h265),
                      </p>
                      <br />
                      体积要限制在1GB以内，否则无法上传。
                      <br />
                      提示：为避免网络中途断开，请将1GB以上的视频分段裁剪上传。
                      <br />
                      上传成功后，点击投稿管理查看审核状态。
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.mp4file != null &&
                  this.state.uploading == true ? (
                    <Progress
                      style={{ margin: "100px 0px" }}
                      type="circle"
                      percent={parseInt(this.state.uploadStatus.toFixed(0))}
                      status="active"
                    />
                  ) : (
                    ""
                  )}
                </div>
              }
            />

            {/**                    <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">拖动到此处 / 或点击上传视频</p>
                <p className="ant-upload-hint">
                    视频mime类型只接受mp4(h264，h265),<br />
                    体积要限制在1GB以内，否则无法上传。<br />
                    提示：为避免网络中途断开，请将1GB以上的视频分段裁剪上传。<br />
                    上传成功后，点击投稿管理查看审核状态。
                </p>
            </Dragger>
                             * 
                             */}

            <br />
            <br />
            <div className="start-uploads" onClick={this.sumbitUploads}>
              {this.state.uploading == true ? (
                <Button disabled={true}>
                  <CloudUploadOutlined />
                  {this.state.uploadStatus}% 上传中
                </Button>
              ) : (
                <Button>
                  <CloudUploadOutlined />
                  开始上传
                </Button>
              )}
            </div>
            <br />
            <br />
            <Collapse>
              <Panel header="投稿审核要多长时间？" key="1">
                <div>非节假日的情况下，（周一至周五）1个工作日内完成审核。</div>
                <div>特殊情况下需要1-3个工作日。</div>
              </Panel>
              <Panel header="审核通过的标准是什么？" key="2">
                1.私人制作的视频需要没有水印（国产AV 厂家浮动水印除外）。
                <br />
                2.内容需要符合18岁成人标准。
                <br />
                3.视频的分辨率不能低于720P。
                <br />
                4.为避免做无用功，请仔细参考以上标准。
              </Panel>
              <Panel header="投稿的好处是什么？" key="3">
                1. 无需为自己投稿的视频花费点播券。
                <br />
                2. 更高的曝光量，通过后您的视频会出现在首页。
                <br />
                3. 投稿优质视频，还可获得点播券奖励。
                <br />
                4. 91HUB独家的CDN云分发，为您节省流量费用。
              </Panel>
              <Panel header="可以下架吗？创作权归属谁？" key="4">
                <div>
                  因服务器成本高，我们需要您的视频创作来抵销成本，故不能下架。
                  <br />
                  当您视频上传到91HUB服务器开始，您的版权将自动授权给91HUB，同时最终解释权也归91HUB所有。
                  <br />
                </div>
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="管理" key="2">
            <div className="manage-video">
              <div className="manage-title">
                <h1>我的视频</h1>
                <div className="manage-tab">
                  <div
                    className={
                      this.state.mleft == true
                        ? "manage-left active"
                        : "manage-left"
                    }
                    onClick={() => (
                      this.setState({ mleft: true, mright: false }),
                      this.axiosGet("1", "0")
                    )}
                  >
                    发布中
                  </div>
                  <div className="manage-spliter"></div>
                  <div
                    className={
                      this.state.mright == true
                        ? "manage-right active"
                        : "manage-right"
                    }
                    onClick={() => (
                      this.setState({ mleft: false, mright: true }),
                      this.axiosGet("1", "1")
                    )}
                  >
                    已发布
                  </div>
                </div>
              </div>
              <div className="manage-body">
                {this.state.videolist.length > 0 ? (
                  <ListItems
                    items={this.state.videolist}
                    formatDate={this.formatDate.bind(this)}
                    height={"200px"}
                  />
                ) : (
                  <div className="loading">
                    <LoadingOutlined />
                  </div>
                )}
                <br />
                <br />
                <Pagination
                  onChange={this.onChangePagination}
                  current={this.state.currentPage}
                  defaultPageSize={12}
                  defaultCurrent={1}
                  total={this.state.total}
                  showSizeChanger={false}
                />
                <br />
                <br />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Uploads;
