import React, { useRef, useState } from "react";
import VideoPlayer from "./player";
import {
  Row,
  Col,
  Card,
  Button,
  message,
  Modal,
  Radio,
  Space,
  RadioChangeEvent,
  Dropdown,
  Drawer,
  Alert,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import md5 from "md5";
import momentZone from "moment-timezone";
import { encode, decode } from "js-base64";
import "../detail.css";
import {
  LikeOutlined,
  DislikeOutlined,
  FolderAddOutlined,
  ShareAltOutlined,
  QrcodeOutlined,
  CloudOutlined,
  ExceptionOutlined,
  FireOutlined,
  InfoCircleOutlined,
  EllipsisOutlined,
  ExpandAltOutlined,
  ExclamationCircleOutlined,
  PayCircleOutlined,
  UpOutlined,
  DownOutlined,
  CommentOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import qs from "qs";
import { Cookies } from "react-cookie";
import { QRCodeSVG } from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import "videojs-plus";
import "videojs-plus/dist/videojs-plus.css";
import videojs from "video.js";
import CommentArea from "./comments";
import type { MenuProps } from "antd";
import ReactGA from "react-ga4";

ReactGA.initialize("G-883ML6DGJC");
interface props {}
interface stateType {
  openTips: boolean;
  streamToken: string;
  signGet: string;
  width: number;
  heightCard: string;
  videoJsOptions: {
    autoplay: boolean;
    controls: boolean;
    errorDisplay: false;
    sources: [
      {
        src: string;
        type: string;
      }
    ];
  };
  recommend: never[];
  taglist: any;
  comments: CommentItem[];
  submitting: boolean;
  commentValue: string;
  commentReplyValue: string;
  videoId: string;
  playData: any;
  tags: any;
  visibleSetting: boolean;
  visibleTitle: string;
  visibleType: string;
  shareUrl: string;
  copied: boolean;
  reportValue: number;
  cdnValue: string;
  playerFullScreen: boolean;
  commentsData: any;
  commentInputId: number;
  commentHoverId: string;
  commentType: number;
  commentFrom: string;
  commentInputBoolean: boolean;
  source: string;
  ticketShow: boolean;
  openComment: boolean;
  commentTotal: number;
  buyModal: boolean;
  isLogin: boolean;
  nodestate: string;
  curDomain: string;
}
interface CommentItem {
  author: string;
  avatar: string;
  content: React.ReactNode;
  datetime: string;
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  submitting: boolean;
  value: string;
}

class Detail extends React.PureComponent<props, stateType> {
  submitting: any;
  constructor(props: props | Readonly<props>) {
    super(props);
    console.log("running");
    var date = md5(
      momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
    );
    this.state = {
      openTips: false,
      streamToken: "",
      commentTotal: 0,
      openComment: false,
      signGet: date,
      width: window.innerWidth,
      heightCard: "100px",
      videoJsOptions: {
        errorDisplay: false,
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      },
      recommend: [],
      taglist: [],
      comments: [],
      submitting: false,
      commentValue: "",
      commentReplyValue: "",
      videoId: "",
      playData: [],
      tags: [],
      visibleSetting: false,
      visibleTitle: "",
      visibleType: "",
      shareUrl: "",
      copied: false,
      reportValue: 0,
      cdnValue: "",
      playerFullScreen: false,
      commentsData: [],
      commentInputId: 0,
      commentHoverId: "",
      commentType: 0,
      commentFrom: "",
      commentInputBoolean: false,
      source: "",
      ticketShow: false,
      buyModal: false,
      isLogin: false,
      nodestate: "",
      curDomain: "",
    };
  }

  reset() {
    var date = md5(
      momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
    );
    this.setState({
      buyModal: false,
      signGet: date,
      width: window.innerWidth,
      heightCard: "100px",
      videoJsOptions: {
        errorDisplay: false,
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      },
      recommend: [],
      taglist: [],
      comments: [],
      submitting: false,
      commentValue: "",
      commentReplyValue: "",
      videoId: "",
      playData: [],
      tags: [],
      visibleSetting: false,
      visibleTitle: "",
      visibleType: "",
      shareUrl: "",
      copied: false,
      reportValue: 0,
      cdnValue: "",
      playerFullScreen: false,
      commentsData: [],
      commentInputId: 0,
      commentHoverId: "",
      commentType: 0,
      commentFrom: "",
      commentInputBoolean: false,
      source: "",
      ticketShow: false,
      isLogin: false,
      nodestate: "",
    });
    this.Mount();
  }

  Mount() {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/QQBrowser/i)) {
      alert("暂不支持此浏览器播放视频，请使用chrome，X浏览器，夸克进行播放。");
      window.location.replace("/index");
      return false;
    }
    if (userAgent.match(/UCBrowser/i)) {
      alert("暂不支持此浏览器播放视频，请使用chrome，X浏览器，夸克进行播放。");
      window.location.replace("/index");
      return false;
    }

    const cookies = new Cookies();

    if (cookies.get("username") == null || cookies.get("username").length < 1) {
      this.setState({ isLogin: false });
    } else {
      this.setState({ isLogin: true });
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const videoId = params.get("v");
    if (videoId != null) {
      console.log(decode(videoId.replace("_", "=")));
      this.setState({
        videoId: decode(videoId.replace("_", "=")),
        shareUrl:
          "https://" +
          document.location.hostname +
          ":" +
          window.location.port +
          "/detail?v=" +
          videoId,
      });
    }

    this.setState({ taglist: ["国产", "日韩", "欧美"] });

    var handle = setInterval(() => {
      if (document.getElementsByClassName("ant-card")) {
        if (document.getElementsByClassName("ant-card")[0]) {
          if (document.getElementsByClassName("ant-card")[0].clientWidth) {
            if (
              document.getElementsByClassName("ant-card")[0].clientWidth !=
                null &&
              document.getElementsByClassName("ant-card")[0].clientWidth != 0
            ) {
              if (this.state.heightCard == "100px") {
                this.setState({
                  heightCard:
                    document.getElementsByClassName("ant-card")[0].clientWidth *
                      0.7 +
                    "px",
                });
              }
            } else {
              clearInterval(handle);
            }
          }
        }
      }
    }, 100);

    axios
      .get(window.apiUrl + "/Application/geoIp?sign=" + this.state.signGet)
      .then((response: { data: any }) => {
        console.log(response.data);
        var jsonIp = response.data;
        if (jsonIp.country == "China") {
          cookies.set("country", "China");
        } else {
          cookies.set("country", "OtherPlace");
        }
      })
      .catch(function (error: any) {});

    setTimeout(() => {
      const _this = this;
      console.log(this.state.videoJsOptions);
      // instantiate Video.js
      //window.addEventListener("resize", this.handleWindowSizeChange);
      axios
        .get(
          window.apiUrl +
            "/Application/recommend/" +
            this.state.videoId +
            "?type=vue&sign=" +
            this.state.signGet
        )
        .then(function (response: { data: any }) {
          //console.log(response.data)
          var decode5 = _this.keyHash(response.data);
          let reg = new RegExp("\\+", "g"); //g代表全部
          let newMsg = decode5.replace(reg, "  ");
          var result = JSON.parse(newMsg);
          const data = result;
          const isMobile = window.innerWidth <= 820;
          _this.setState({
            recommend: isMobile == false ? data.slice(0, 8) : data,
          });

          //const myElement = document.querySelector(".ant-card");
        })
        .catch(function (error: any) {
          console.log(error);
        });

      axios
        .get(
          window.apiUrl +
            "/Application/play/" +
            this.state.videoId +
            "?type=vue&sign=" +
            this.state.signGet
        )
        .then((response: { data: any }) => {
          this.commentTotal();
          //console.log(response.data)
          var decode5 = _this.keyHash(response.data);
          let reg = new RegExp("\\+", "g"); //g代表全部
          let newMsg = decode5.replace(reg, "  ");
          var result = JSON.parse(newMsg);
          const data = result;
          this.setState({
            playData: data,
            source: data[0].source,
          });
          document.title = data[0]["title"] + " - " + window.title;
          ReactGA.send({
            hitType: "pageview",
            page: "/watch?v=" + this.postidBase(data[0]["Id"]),
            title: data[0]["title"] + " - " + window.title,
          });
          this.addToHistory(data[0]);
          console.log(data);
          console.log(data[0].second);
          if (parseInt(data[0]["second"]) < 300) {
            if (
              cookies.get("username") == null ||
              cookies.get("username") == undefined ||
              cookies.get("username").length == 0
            ) {
              this.error("此视频需要登录观看");
              setTimeout(() => {
                window.location.replace("/account?action=login");

                return false;
              }, 300);
            }
          }

          if (
            cookies.get("username") == null ||
            cookies.get("username") == undefined ||
            cookies.get("username").length == 0
          ) {
            this.setState({
              ticketShow: parseInt(data[0].second) <= 299 ? false : true,
            });
            axios
              .get(
                window.apiUrl +
                  "/Application/stream?Id=" +
                  data[0]["Id"] +
                  "&sign=" +
                  this.state.signGet
              )
              .then((response: { data: any }) => {
                const result = JSON.parse(response.data);
                this.setState({
                  streamToken: result.token,
                  ticketShow: false,
                  nodestate: "stream",
                });
                setTimeout(() => {
                  videojs("myvideo").src({
                    src: this.loadVideo("stream"),
                    type: "application/x-mpegURL",
                  });
                }, 500);

                return false;
              })
              .catch((error: any) => {
                console.log(error);
                if (error.response.status == 400) {
                  if (localStorage.getItem("alert3free") == null) {
                    localStorage.setItem("alert3free", "open");
                  }

                  setTimeout(() => {
                    videojs("myvideo").src({
                      src: this.loadVideo("short"),
                      type: "application/x-mpegURL",
                    });
                  }, 500);
                }
              });
          }
          if (
            cookies.get("username") != null ||
            cookies.get("username") != undefined
          ) {
            axios
              .post(
                window.apiUrl +
                  "/Application/buy_check/" +
                  this.state.videoId +
                  "?type=vue&sign=" +
                  this.state.signGet,
                qs.stringify({
                  username: cookies.get("username"),
                  password: cookies.get("password"),
                }),
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then((response: { data: any; status: any; headers: any }) => {
                this.setState({
                  ticketShow: false,
                });

                console.log(this.state.ticketShow);
                setTimeout(() => {
                  videojs("myvideo").src({
                    src: this.loadVideo("full"),
                    type: "application/x-mpegURL",
                  });
                }, 500);
              })
              .catch(() => {
                this.setState({
                  ticketShow: true,
                });
                setTimeout(() => {
                  videojs("myvideo").src({
                    src: this.loadVideo("short"),
                    type: "application/x-mpegURL",
                  });
                }, 500);
              });
          }

          console.log(data);
          if (data[0].tags != null) {
            this.setState({ tags: result[0].tags.replace(",,", ",") });
            this.tagsFor(result[0].tags);
          }
          console.log(this.loadVideo("short"));
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        })
        .catch(function (error: any) {
          console.log(error);
        });
    }, 200);

    function getMainDomain(url: string) {
      // 移除协议部分
      url = url.replace(/^(https?:|)\/\//, "");

      // 移除端口部分
      url = url.replace(/:\d+/, "");

      // 分割主机名
      var parts = url.split(".");

      // 如果主机名的部分大于等于3，例如"chat.openai.com"，则返回最后两部分
      if (parts.length >= 3) {
        return parts[parts.length - 2] + "." + parts[parts.length - 1];
      } else {
        // 否则返回整个主机名
        return url;
      }
    }

    this.setState({
      curDomain: getMainDomain(
        window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "")
      ),
    });
  }

  componentDidMount() {
    document.title = "视频载入中 - " + window.title;
    this.Mount();

    const cookies = new Cookies();
    if (cookies.get("username") != null && cookies.get("username").length > 0) {
      if (cookies.get("vef") == null || cookies.get("vef") != "active") {
        window.location.replace("/account?action=verify");
        return false;
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {}

  handleWindowSizeChange = () => {
    return () => {
      this.setState({ width: window.innerWidth });
    };
  };

  keyHash = (key: string) => {
    if (key == "400" || key.length == 0) {
      return "";
    } else {
      var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .add(1, "hours")
        .format("YYYY-MM-DD-HH");
      var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .subtract(1, "hours")
        .format("YYYY-MM-DD-HH");

      var add = encode(md5(date1 + "sign91")).replace("=", "");
      var subtract = encode(md5(date2 + "sign91")).replace("=", "");

      var length1 = key.length;
      var length2 = length1 - 20;
      var content1 = key.substring(length2, length1);
      var content2 = key.substring(0, length2);
      ///////////////////////////////////////////
      var keys = encode(this.state.signGet).replace("=", "");
      let reg = new RegExp("END", "g"); //g代表全部
      var keyHashFirst = content1.replace(reg, "");
      var keyHash = content2 + keyHashFirst;
      var keyResult = keyHash.replace(keys, "");
      var keyResult1 = keyResult.replace(subtract, "");
      var keyResult2 = keyResult1.replace(add, "");
      return decode(keyResult2.toString());
    }
  };

  formatDate(value: any) {
    const s = 1000,
      m = 60 * s,
      h = 60 * m,
      d = 24 * h,
      mo = 30 * d,
      y = 365 * d;

    const fdate = [
      {
        time: y,
        text: "年",
      },
      {
        time: mo,
        text: "月",
      },
      {
        time: d,
        text: "天",
      },
      {
        time: h,
        text: "小时",
      },
      {
        time: m,
        text: "分钟",
      },
      {
        time: s,
        text: "秒",
      },
    ];
    //return "andriod";
    // s m h d mo y
    // 计算出时间差
    const time = Date.now() - parseInt(new Date(value).valueOf().toString());
    for (let i = 0; i < fdate.length; i++) {
      const t = time / fdate[i].time;
      if (t >= 1) {
        return Math.floor(t) + fdate[i].text + "前";
      }
    }
  }
  uniq(array: string | any[]) {
    let temp = [];
    let index = [];
    let l = array.length;
    for (let i = 0; i < l; i++) {
      for (let j = i + 1; j < l; j++) {
        if (array[i] === array[j]) {
          i++;
          j = i;
        }
      }
      temp.push(array[i]);
      index.push(i);
    }
    return temp;
  }

  tagsFor(str: string) {
    var t = str.replace("暂无标签", "");
    var getLengthX = t.replace(",,", "");
    var reg = /,$/gi;
    var resultStr = getLengthX.replace(reg, "");
    console.log(resultStr.split(","));
    this.setState({ tags: resultStr.split(",") });
    if (this.state.tags.length > 1) {
      this.setState({ tags: this.uniq(this.state.tags) });
    }
    console.log(this.state.tags);
  }

  success = (msg: string) => {
    message.success(msg);
  };

  error = (msg: string) => {
    message.error(msg);
  };

  warning = (msg: any) => {
    message.warning(msg);
  };

  playerAction(val: any) {
    const cookies = new Cookies();
    if (val == "good") {
      axios
        .post(
          window.apiUrl +
            "/Application/player_action/" +
            this.state.videoId +
            "?type=good&sign=" +
            this.state.signGet,
          qs.stringify({
            username: cookies.get("username"),
            password: cookies.get("password"),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response: { data: any }) => {
          if (response.data.msg != "已点赞") {
            this.error(response.data.msg);
          } else {
            this.success("已点赞");
          }
          return false;
        })
        .catch(function (error: any) {
          console.log(error);
        });
      return false;
    }
    if (val == "nogood") {
      axios
        .post(
          window.apiUrl +
            "/Application/player_action/" +
            this.state.videoId +
            "?type=nogood&sign=" +
            this.state.signGet,
          qs.stringify({
            username: cookies.get("username"),
            password: cookies.get("password"),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response: { data: any }) => {
          if (response.data.msg != "已踩") {
            this.error(response.data.msg);
          } else {
            this.success("已踩");
          }
          return false;
        })
        .catch(function (error: any) {
          console.log(error);
        });
      return false;
    }
    if (val == "favorite") {
      axios
        .post(
          window.apiUrl +
            "/Application/player_action/" +
            this.state.videoId +
            "?type=favorite&sign=" +
            this.state.signGet,
          qs.stringify({
            username: cookies.get("username"),
            password: cookies.get("password"),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response: { data: any }) => {
          if (response.data.msg != "已收藏") {
            this.error(response.data.msg);
          } else {
            this.success("已收藏");
          }
          return false;
        })
        .catch(function (error: any) {
          console.log(error);
        });
      return false;
    }
  }

  onChangeReport = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    this.setState({ reportValue: e.target.value });
    const cookies = new Cookies();
    if (cookies.get("username") == null || cookies.get("username").length < 4) {
      this.error("请先登录");
      return false;
    }
    setTimeout(() => {
      axios
        .post(
          window.apiUrl +
            "/Application/reportVideo?videoId=" +
            this.state.videoId +
            "&reportId=" +
            e.target.value +
            "&sign=" +
            this.state.signGet,
          qs.stringify({
            username: cookies.get("username"),
            password: cookies.get("password"),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response: { data: any }) => {
          this.success("提交成功，感谢您的反馈");
          setTimeout(() => {
            this.setState({ visibleSetting: false });
          }, 2000);
          return false;
        })
        .catch((error: any) => {
          setTimeout(() => {
            this.setState({ visibleSetting: false });
          }, 2000);
          console.log(error);
          if (error.response.status == 400 || error.response.status == 402) {
            this.error("请先登录");
          }
          if (error.response.status == 403) {
            this.error("您已反馈过");
          }
          if (error.response.status == 502) {
            this.error("视频不存在");
          }
        });
    }, 500);
    return false;
  };

  commentTotal = () => {
    axios
      .get(
        window.apiUrl +
          "/Application/commentsTotal?Id=" +
          this.state.videoId +
          "&sign=" +
          this.state.signGet
      )
      .then((response: { data: any }) => {
        var decode5 = this.keyHash(response.data);
        let reg = new RegExp("\\+", "g"); //g代表全部
        let newMsg = decode5.replace(reg, "  ");
        var result = JSON.parse(newMsg);
        const data = result;
        this.setState({ commentTotal: data.total });
      })
      .catch((error: any) => {});
  };

  onChangeCdn = (e: RadioChangeEvent) => {
    console.log("切换节点为：", e.target.value);
    this.setState({ cdnValue: e.target.value });
    if (this.state.nodestate == "stream") {
      this.error("登录后可切换线路");
      setTimeout(() => {
        this.setState({ visibleSetting: false });
      }, 2000);

      return false;
    }

    if (this.state.ticketShow == true) {
      this.error("试看暂不支持切换线路");
      setTimeout(() => {
        this.setState({ visibleSetting: false });
      }, 2000);

      return false;
    }
    if (e.target.value != undefined || e.target.value != null) {
      setTimeout(() => {
        videojs("myvideo").src({
          src: this.loadVideo(e.target.value),
          type: "application/x-mpegURL",
        });
        //this.setState({
        //  videoJsOptions: {
        //    errorDisplay: false,
        //    autoplay: false,
        //    controls: true,
        //    sources: [{
        //      src: this.loadVideo(e.target.value),
        //      type: 'application/x-mpegURL'
        //    }]
        //  }
        //});
      }, 200);
      this.success("节点切换成功");
    }
    setTimeout(() => {
      this.setState({ visibleSetting: false });
    }, 2000);

    return false;
  };

  timeGet = () => {
    var now = momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH");
    return now;
  };
  signShortGet() {
    var now = momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH");
    return md5(now + "IUHsk2H2je9s");
  }

  loadVideo = (types: string) => {
    var player = videojs("myvideo");
    console.log(this.state.playData[0]);
    player.title(this.state.playData[0]!.title);

    const cookies = new Cookies();
    const username = cookies.get("username");
    const password = cookies.get("password");
    let short = "short";
    let full = "full";
    var url = "";
    if (cookies.get("country") == "China") {
      short = "v";
      full = "m";
    } else {
      short = "vcf";
      full = "mcf";
    }
    if (types == "short") {
      url =
        "https://vod." +
        window.baseUrl +
        "/vod/m3u8/v1_encryption?auth=" +
        encodeURIComponent("guest") +
        "&token=" +
        this.signShortGet() +
        "&sign=" +
        md5(this.timeGet()) +
        "&onlyId=" +
        this.state.source +
        "&n=" +
        short;
      return url;
    }
    if (types == "stream") {
      cookies.get("country") == "China"
        ? (url =
            "https://vod." +
            window.baseUrl +
            "/vod/m3u8/v2_encryption?token=" +
            this.signShortGet() +
            "&sign=" +
            md5(this.timeGet()) +
            "&useraes=" +
            this.state.streamToken +
            "&onlyId=" +
            this.state.source +
            "&ip=" +
            localStorage.getItem("ipaddress") +
            "&n=str")
        : (url =
            "https://vod." +
            window.baseUrl +
            "/vod/m3u8/v2_encryption?token=" +
            this.signShortGet() +
            "&sign=" +
            md5(this.timeGet()) +
            "&useraes=" +
            this.state.streamToken +
            "&onlyId=" +
            this.state.source +
            "&ip=" +
            localStorage.getItem("ipaddress") +
            "&n=strcf");
      return url;
    }
    if (types == "full") {
      url =
        "https://vod." +
        window.baseUrl +
        "/vod/m3u8/v3_encryption?Id=" +
        this.state.source +
        "&useraes=" +
        md5(md5(password) + this.timeGet()) +
        "&auth=" +
        encodeURIComponent(username) +
        "&token=" +
        md5(username + "JHD8Djd3g1HD") +
        "&sign=" +
        md5(this.timeGet()) +
        "&onlyId=" +
        this.state.source +
        "&n=" +
        full;
      return url;
    }
    if (
      types.substring(0, 4) == "node" &&
      parseInt(types.substring(4, 5)) < 6
    ) {
      url =
        "https://vod." +
        window.baseUrl +
        "/vod/m3u8/v3_encryption?Id=" +
        this.state.source +
        "&useraes=" +
        md5(md5(password) + this.timeGet()) +
        "&auth=" +
        encodeURIComponent(username) +
        "&token=" +
        md5(username + "JHD8Djd3g1HD") +
        "&sign=" +
        md5(this.timeGet()) +
        "&onlyId=" +
        this.state.source +
        "&n=n" +
        types.substring(4, 5);
      return url;
    } else {
      url =
        "https://vod." +
        window.baseUrl +
        "/vod/m3u8/v3_encryption?Id=" +
        this.state.source +
        "&useraes=" +
        md5(md5(password) + this.timeGet()) +
        "&auth=" +
        encodeURIComponent(username) +
        "&token=" +
        md5(username + "JHD8Djd3g1HD") +
        "&sign=" +
        md5(this.timeGet()) +
        "&onlyId=" +
        this.state.source +
        "&n=m";
      return url;
    }
    return url;
    //试看
    //免费
    //完整
  };

  hd = (value: string) => {
    if (parseInt(value) == 720 || parseInt(value) < 1080) {
      return "HD";
    }
    if (parseInt(value) < 720) {
      return "SD";
    }
    if (parseInt(value) >= 1080) {
      return "1080P";
    }
  };

  buyItem = (Id: any) => {
    const cookies = new Cookies();

    if (cookies.get("username") == null || cookies.get("username").length < 1) {
      this.error("请先登录");
      this.setState({ buyModal: false });
      return false;
    }
    setTimeout(() => {
      axios
        .post(
          window.apiUrl +
            "/Application/buy_item/" +
            this.state.videoId +
            "?type=vue&sign=" +
            this.state.signGet,
          qs.stringify({
            username: cookies.get("username"),
            password: cookies.get("password"),
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response: { data: any }) => {
          this.setState({ buyModal: false });
          this.success("支付成功，可继续观看完整版");
          window.location.reload();
          return false;
        })
        .catch((error: any) => {
          this.setState({ buyModal: false });
          console.log(error);
          if (error.response.status == 400) {
            //this.error("你已购买过该视频，无需再次购买");
          }
          if (error.response.status == 403) {
            this.error("点播券不足");
          }
          if (error.response.status == 500) {
            this.error("请重新登录");
          }
        });
    }, 500);
    return false;
  };

  postidBase(Id: string) {
    if (Id != null) {
      return encode(Id).replace("=", "_");
    }
  }

  goTo = (id: any) => {
    let navigate = useNavigate();
    return () => {
      //navigate("../i", { replace: true });
      setTimeout(() => {
        navigate("/detail?v=" + this.postidBase(id), { replace: true });
      }, 100);
      setTimeout(() => {
        this.reset();
      }, 200);
    };
  };

  closeFree = () => {
    localStorage.setItem("alert3free", "close");
  };

  addToHistory = (item: any) => {
    var check = localStorage.getItem("localhistory");
    var checkJson = check != null ? JSON.parse(check) : {};
    if (checkJson != null) {
      if (checkJson.length > 24) {
        checkJson.shift();
        var list = checkJson;
        var c = false;
        for (var i = 0; i < list.length; i++) {
          if (list[i].Id != item.Id) {
            var list1 = list.concat(item);
            localStorage.setItem("localhistory", JSON.stringify(list1));
            console.log("历史记录数量:" + list1.length.toString());
          } else {
            c = true;
          }
        }
        //this.success("已添加历史观看");
        if (c == false) {
        } else {
          return false;
        }
      }
    }
    if (check != null) {
      for (var i = 0; i < checkJson.length; i++) {
        if (checkJson[i].Id === item.Id) {
          return false;
        }
      }
    }
    if (check == null) {
      list = [item];
      localStorage.setItem("localhistory", JSON.stringify(list));
      console.log("历史记录数量:" + list.length.toString());
      return false;
    }
    if (check != null) {
      if (checkJson.length < 25) {
        list = check != null ? JSON.parse(check) : {};
        var c = false;
        if (list.length < 25) {
          for (var i = 0; i < list.length; i++) {
            if (list[i].Id != item.Id) {
              var list1 = list.concat(item);
              localStorage.setItem("localhistory", JSON.stringify(list1));
              console.log("历史记录数量:" + list1.length.toString());
            } else {
              c = true;
            }
          }
          //this.success("已添加历史观看");
          if (c == false) {
          } else {
            return false;
          }
        }
      }
    }
  };

  buy = () => {
    this.buyItem(this.state.videoId);
  };

  showPromiseConfirm = () => {
    this.setState({
      openTips: true,
    });
  };
  nsfw(str: string) {
    return str.includes("**");
  }

  render() {
    const isMobile = this.state.width <= 820;
    function RecommendEmpty(props: { items: any; height: any }) {
      //const alertpop = alertpop()
      const items = props.items;
      const height = props.height;
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      const listItem = items.map(
        (item: any, i: React.Key | null | undefined) => (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            span={24}
            key={i}
            style={{ margin: "0px 0px 10px 0px" }}
          >
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              span={11}
              className="recommend-left"
            >
              <div
                className="skeleton"
                style={{ width: "100%", height: height }}
              >
                {" "}
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              span={11}
              className="recommend-right"
            >
              <div
                className="skeleton"
                style={{ width: "100%", height: "20px" }}
              >
                {" "}
              </div>
              <div
                className="skeleton"
                style={{ width: "100%", height: "10px", marginTop: "10px" }}
              >
                {" "}
              </div>
            </Col>
          </Col>
        )
      );

      return <Row>{listItem}</Row>;
    }

    function RecommendEmptyMobile(props: { items: any; height: any }) {
      //const alertpop = alertpop()
      const items = props.items;
      const height = props.height;
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      const listItem = items.map(
        (item: any, i: React.Key | null | undefined) => (
          <div style={{ width: "50%", padding: "10px" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              span={24}
              className=""
            >
              <div
                className="skeleton"
                style={{ width: "100%", height: height }}
              >
                {" "}
              </div>
              <div
                className="skeleton"
                style={{ width: "100%", height: "20px" }}
              >
                {" "}
              </div>
              <div
                className="skeleton"
                style={{ width: "100%", height: "10px", marginTop: "10px" }}
              >
                {" "}
              </div>
            </Col>
          </div>
        )
      );

      return <Row>{listItem}</Row>;
    }

    function RecommendItemsMobile(props: {
      items: any;
      height: any;
      formatDate: any;
      goTo: any;
      hd: any;
      nsfw: any;
    }) {
      //const alertpop = alertpop()
      const items = props.items;
      const height = props.height;
      const formatDate = props.formatDate;
      const goTo = props.goTo;
      const hd = props.hd;
      const nsfw = props.nsfw;
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      const listItem = items.map(
        (
          item: {
            resolution: any;
            Id: React.Key | null | undefined;
            duration:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            source: string;
            title:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            username:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            addtime: any;
          },
          i: any
        ) => (
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} span={12}>
            <Card
              onClick={goTo(item.Id)}
              hoverable
              cover={
                <a>
                  <div className="videoPoster">
                    <div className="resolution">
                      {nsfw(item.title) == true
                        ? "NSFW"
                        : hd(item.resolution.split(",")[1])}
                    </div>
                    <div className="duration">{item.duration}</div>
                    <div className="videoBox-bottom"></div>
                    <div className="videoCard">
                      <img
                        src={window.cacheUrl + "/" + item.source + "/thumb.jpg"}
                        style={{ height: height, width: "100%" }}
                      />
                    </div>{" "}
                  </div>
                </a>
              }
            ></Card>
            <a>
              <p className="videoName">{item.title}</p>
              <div className="videoInfoMore">
                <span className="smalltext">{item.username}</span>
                <span className="smalltext">{formatDate(item.addtime)}</span>
              </div>
            </a>
          </Col>
        )
      );

      return <Row>{listItem}</Row>;
    }

    function RecommendItems(props: {
      items: any;
      height: any;
      formatDate: any;
      goTo: any;
      hd: any;
      nsfw: any;
    }) {
      //const alertpop = alertpop()
      const items = props.items;
      const height = props.height;
      const formatDate = props.formatDate;
      const goTo = props.goTo;
      const hd = props.hd;
      const nsfw = props.nsfw;
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      const listItem = items.map(
        (
          item: {
            resolution: any;
            Id: React.Key | null | undefined;
            duration:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            source: string;
            title:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            username:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            addtime: any;
          },
          i: any
        ) => (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            span={24}
            key={item.Id + i}
          >
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              span={11}
              className="recommend-left"
            >
              <Card
                onClick={goTo(item.Id)}
                hoverable
                cover={
                  <a>
                    <div className="videoPoster">
                      <div className="resolution">
                        {hd(item.resolution.split(",")[1])}
                      </div>
                      <div className="duration">{item.duration}</div>
                      <div className="videoBox-bottom"></div>
                      <div className="videoCard">
                        <img
                          src={
                            window.cacheUrl + "/" + item.source + "/thumb.jpg"
                          }
                          style={
                            nsfw(item.title) == true
                              ? {
                                  height: height,
                                  width: "100%",
                                  filter: "blur(3px)",
                                }
                              : {
                                  height: height,
                                  width: "100%",
                                  filter: "unset",
                                }
                          }
                        />
                      </div>{" "}
                    </div>
                  </a>
                }
              ></Card>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}
              xxl={11}
              span={11}
              className="recommend-right"
              onClick={goTo(item.Id)}
            >
              <a>
                <p className="videoName">{item.title}</p>
                <div className="videoInfoMore">
                  <span className="smalltext">{item.username}</span>
                  <span className="smalltext">{formatDate(item.addtime)}</span>
                </div>
              </a>
            </Col>
          </Col>
        )
      );

      return <Row>{listItem}</Row>;
    }
    function ActorItems(props: { items: any }) {
      const items = props.items;
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      const listItem = items.map((item: any, i: any) => (
        <a
          href={"/product?s=" + item}
          style={{ color: "black", marginRight: "10px" }}
        >
          {item}
        </a>
      ));

      return <div style={{ float: "right" }}>{listItem}</div>;
    }

    function TagItems(props: { items: any }) {
      const items = props.items;
      // 只需要保证同一次遍历中 key 不同。 listItems 和 sideBy 属于不同的遍历，可以用相同的key
      const listItem = items.map((item: any, i: any) => (
        <li className="tagName">
          <a href={"/tags?s=" + item}>{item}</a>
        </li>
      ));

      return <ul className="tagUl">{listItem}</ul>;
    }

    const Dialog = (props: {
      visible: any;
      title: any;
      type: any;
      onChangeReport: any;
      reportValue: any;
      onChangeCdn: any;
      cdnValue: any;
    }) => {
      const visible = props.visible;
      const title = props.title;
      const type = props.type;
      const onChangeReport = props.onChangeReport;
      const reportValue = props.reportValue;
      const onChangeCdn = props.onChangeCdn;
      const cdnValue = props.cdnValue;
      return (
        <Modal
          title={title}
          centered
          footer={null}
          visible={visible}
          transitionName=""
          onOk={() => this.setState({ visibleSetting: false })}
          onCancel={() => this.setState({ visibleSetting: false })}
          width={250}
        >
          {type == "share" ? (
            <div>
              <QRCodeSVG value={this.state.shareUrl} />
              <br />
              <br />
              <CopyToClipboard
                text={this.state.shareUrl}
                onCopy={() => (
                  this.setState({ copied: true, visibleSetting: false }),
                  this.success("复制成功")
                )}
              >
                <Button>点击复制链接</Button>
              </CopyToClipboard>
            </div>
          ) : (
            ""
          )}
          {type == "mobile" ? (
            <div>
              <QRCodeSVG value={this.state.shareUrl} />
              <br />
              <br /> <p>扫码使用手机观看</p>
            </div>
          ) : (
            ""
          )}
          {type == "report" ? (
            <div>
              <Radio.Group
                defaultValue={"播放失效"}
                onChange={onChangeReport}
                value={reportValue}
                style={{ width: "100%", marginBottom: "20px" }}
              >
                <Space direction="vertical">
                  <Radio value={1}>播放失效</Radio>
                  <Radio value={2}>播放卡顿</Radio>
                  <Radio value={3}>拖动异常</Radio>
                  <Radio value={4}>内容花屏</Radio>
                  <Radio value={5}>播放器BUG</Radio>
                  <Radio value={6}>网页速度慢</Radio>
                  <Radio value={7}>画质不清晰</Radio>
                  <Radio value={8}>内容不当</Radio>
                  <Radio value={9}>广告太多</Radio>
                  <Radio value={10}>欺诈内容</Radio>
                  <Radio value={11}>封面不佳</Radio>
                </Space>
              </Radio.Group>
              <Button onClick={() => this.setState({ visibleSetting: false })}>
                取消
              </Button>
            </div>
          ) : (
            ""
          )}
          {type == "cdn" ? (
            <div>
              <Radio.Group
                onChange={onChangeCdn}
                value={cdnValue}
                style={{ width: "100%", marginBottom: "20px" }}
              >
                <Space direction="vertical">
                  <Radio value={"node1"}>视频节点1</Radio>
                  <Radio value={"node2"}>视频节点2</Radio>
                  <Radio value={"node3"}>视频节点3</Radio>
                  <Radio value={"node4"}>视频节点4</Radio>
                  <Radio value={"media"}>海外节点5</Radio>
                </Space>
              </Radio.Group>
              <Button onClick={() => this.setState({ visibleSetting: false })}>
                取消
              </Button>
            </div>
          ) : (
            ""
          )}
        </Modal>
      );
    };

    function VideoPlayerDiv(props: { videoJsOptions: any }) {
      const videoJsOptions = props.videoJsOptions;
      return <VideoPlayer videoJsOptions={videoJsOptions} />;
    }
    function TicketShow(props: {
      ticketShow: any;
      pay: any;
      showPromiseConfirm: any;
      openTips: any;
      buy: any;
    }) {
      const ticketShow = props.ticketShow;
      const pay = props.pay;
      const showPromiseConfirm = props.showPromiseConfirm;
      const openTips = props.openTips;
      const buy = props.buy;
      let div = <div></div>;
      {
        ticketShow == true
          ? (div = (
              <div className="buyTips" onClick={() => showPromiseConfirm()}>
                <div className="buyLeft">
                  <ExclamationCircleOutlined />
                  {isMobile == true
                    ? "你当前只能试看 120 秒，观看完整版请点击此处"
                    : " 你还未支付点播券，只能试看 120 秒"}
                </div>
                {isMobile == false ? (
                  <div className="buyRight">
                    <Button onClick={() => pay()}>
                      <PayCircleOutlined />
                      支付
                    </Button>
                  </div>
                ) : null}
              </div>
            ))
          : (div = <div></div>);
      }
      return div;
    }
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: (
          <a
            onClick={() =>
              this.setState({
                visibleSetting: true,
                visibleTitle: "反馈此视频",
                visibleType: "report",
              })
            }
          >
            <ExceptionOutlined />
            反馈
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            onClick={() =>
              this.setState({
                visibleSetting: true,
                visibleTitle: "更改线路",
                visibleType: "cdn",
              })
            }
          >
            <CloudOutlined />
            线路
          </a>
        ),
      },
    ];

    return (
      <div id="detailPage" className={isMobile == false ? "" : "detailMobile"}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={this.state.playerFullScreen == true ? 24 : 18}
            lg={this.state.playerFullScreen == true ? 24 : 18}
            xl={this.state.playerFullScreen == true ? 24 : 18}
            xxl={this.state.playerFullScreen == true ? 24 : 18}
            span={this.state.playerFullScreen == true ? 24 : 18}
          >
            {isMobile == false ? (
              <div
                className="playerBox"
                style={
                  this.state.playerFullScreen == true
                    ? {
                        position: "relative",
                        overflow: "hidden",
                        display: "block",
                        height: "80vh",
                      }
                    : {
                        position: "relative",
                        overflow: "hidden",
                        display: "block",
                        height: "60vh",
                      }
                }
              >
                <VideoPlayer videoJsOptions={this.state.videoJsOptions} />
              </div>
            ) : (
              <div
                className="playerBox"
                style={
                  this.state.playerFullScreen == true
                    ? {
                        position: "relative",
                        overflow: "hidden",
                        display: "block",
                        height: "30vh",
                      }
                    : {
                        position: "relative",
                        overflow: "hidden",
                        display: "block",
                        height: "30vh",
                      }
                }
              >
                <VideoPlayer videoJsOptions={this.state.videoJsOptions} />
              </div>
            )}
            {this.state.isLogin == false &&
            localStorage.getItem("alert3free") == "open" ? (
              <Alert
                message="免费次数已用完，登录账号领取点播券观看更多精彩视频"
                type="error"
                showIcon
                closeText="不再提醒"
                onClose={() => this.closeFree()}
              />
            ) : null}

            <TicketShow
              buy={this.buy}
              openTips={this.state.openTips}
              ticketShow={this.state.ticketShow}
              pay={this.buyItem}
              showPromiseConfirm={() => this.setState({ buyModal: true })}
            />
            {isMobile == false ? (
              <Modal
                className="payModal"
                open={this.state.buyModal}
                title="确定支付 1张 点播券 观看完整视频？"
                okText="支付"
                cancelText="取消"
                onOk={this.buyItem}
                onCancel={() => this.setState({ buyModal: false })}
              ></Modal>
            ) : (
              <Modal
                title="提示"
                open={this.state.buyModal}
                onOk={this.buyItem}
                okText="支付"
                cancelText="取消"
                onCancel={() => this.setState({ buyModal: false })}
              >
                <p>你确定花费1张点播券观看此视频</p>
              </Modal>
            )}

            {this.state.playData != null && this.state.playData != "" ? (
              <div>
                {isMobile == false ? (
                  <div className="playerToolBar">
                    <div className="toolBarLeft">
                      <span
                        className="like"
                        onClick={() => this.playerAction("good")}
                      >
                        <LikeOutlined />{" "}
                        <div className="tool-text">
                          {this.state.playData[0].good}
                        </div>{" "}
                      </span>
                      <span
                        className="dislike"
                        onClick={() => this.playerAction("nogood")}
                      >
                        <DislikeOutlined />{" "}
                        <div className="tool-text">
                          {this.state.playData[0].nogood}
                        </div>
                      </span>
                      <span
                        className="favorite"
                        onClick={() => this.playerAction("favorite")}
                      >
                        <FolderAddOutlined />{" "}
                        <div className="tool-text">收藏</div>
                      </span>
                      <span
                        className="share"
                        onClick={() =>
                          this.setState({
                            visibleSetting: true,
                            visibleTitle: "分享此视频",
                            visibleType: "share",
                          })
                        }
                      >
                        <ShareAltOutlined />{" "}
                        <div className="tool-text">分享</div>
                      </span>
                      <span
                        className="mobileQrcode"
                        onClick={() =>
                          this.setState({
                            visibleSetting: true,
                            visibleTitle: "用手机看",
                            visibleType: "mobile",
                          })
                        }
                      >
                        <QrcodeOutlined />{" "}
                        <div className="tool-text">手机看</div>
                      </span>
                    </div>
                    <div className="toolBarRight">
                      <span
                        className="pageFullScreen"
                        onClick={() => {
                          this.state.playerFullScreen == true
                            ? this.setState({ playerFullScreen: false })
                            : this.setState({ playerFullScreen: true });
                        }}
                      >
                        <ExpandAltOutlined />{" "}
                        <div className="tool-text">宽屏模式</div>
                      </span>
                      <span
                        className="node"
                        onClick={() =>
                          this.setState({
                            visibleSetting: true,
                            visibleTitle: "更改线路",
                            visibleType: "cdn",
                          })
                        }
                      >
                        <CloudOutlined /> <div className="tool-text">线路</div>
                      </span>
                      <span
                        className="report"
                        onClick={() =>
                          this.setState({
                            visibleSetting: true,
                            visibleTitle: "反馈此视频",
                            visibleType: "report",
                          })
                        }
                      >
                        <ExceptionOutlined />{" "}
                        <div className="tool-text">反馈</div>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="media-action">
                    <ul>
                      <li>
                        <a onClick={() => this.playerAction("good")}>
                          <LikeOutlined />{" "}
                          <span>{this.state.playData[0].good}</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => this.playerAction("nogood")}>
                          <DislikeOutlined />
                          <span>{this.state.playData[0].nogood}</span>
                        </a>
                      </li>
                      <li>
                        <a onClick={() => this.playerAction("favorite")}>
                          <PlusOutlined />
                          <span>收藏</span>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            this.setState({
                              visibleSetting: true,
                              visibleTitle: "分享此视频",
                              visibleType: "share",
                            })
                          }
                        >
                          <ShareAltOutlined />
                          <span>分享</span>
                        </a>
                      </li>
                      <li>
                        <Dropdown
                          menu={{ items }}
                          placement="bottom"
                          trigger={["click"]}
                        >
                          <a>
                            <EllipsisOutlined />
                            <span>更多</span>
                          </a>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div
                  className="skeleton"
                  style={{
                    width: "100%",
                    height: "80px",
                    margin: "10px 0px",
                  }}
                >
                  {" "}
                </div>
                <div
                  className="skeleton"
                  style={{
                    width: "100%",
                    height: "20px",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                </div>
                <div
                  className="skeleton"
                  style={{ width: "70%", height: "20px", marginBottom: "10px" }}
                >
                  {" "}
                </div>
                <div
                  className="skeleton"
                  style={{
                    width: "100%",
                    height: "60px",
                    margin: "10px 0px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            <div
              style={
                isMobile == false ? {} : { padding: "10px 0px", width: "100%" }
              }
            >
              {this.state.playData != null && this.state.playData != "" ? (
                <div
                  className="playerInfo"
                  style={
                    isMobile == false
                      ? {}
                      : { borderBottom: "1px solid #dcdcdc;" }
                  }
                >
                  <div
                    className="flexTitle"
                    style={isMobile == false ? {} : { padding: "0 10px" }}
                  >
                    {this.state.playData[0].title}{" "}
                  </div>
                  {isMobile == false ? (
                    <div className="flexInfo">
                      {" "}
                      <div className="spanText">
                        <FireOutlined />
                        {this.state.playData[0].views}次观看
                      </div>{" "}
                      <div className="spanText">
                        <InfoCircleOutlined />
                        上传时间：{this.state.playData[0].addtime}
                      </div>{" "}
                      <div className="spanText">
                        <InfoCircleOutlined />
                        视频长度：{this.state.playData[0].duration}
                      </div>{" "}
                      {this.state.playData[0].actor != null &&
                      this.state.playData[0].userid == 4 ? (
                        <div className="spanText">
                          <InfoCircleOutlined />
                          相关演员：
                          <ActorItems
                            items={this.state.playData[0].actor.split(",")}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : this.state.playData[0].actor == null &&
                    this.state.playData[0].userid != 4 ? (
                    <div className="flexInfo" style={{ padding: "0 10px" }}>
                      {" "}
                      <div className="spanText">
                        {this.state.playData[0].views}次观看
                        {" · "}
                        上传于：
                        {this.state.playData[0].addtime}
                        {" · "}
                        时长：
                        {this.state.playData[0].duration}
                      </div>{" "}
                    </div>
                  ) : (
                    <div className="flexInfo" style={{ padding: "0 10px" }}>
                      {" "}
                      <div className="spanText">
                        {this.state.playData[0].views}次观看
                        {" · "}
                        上传于：
                        {this.state.playData[0].addtime}
                        {" · "}
                        时长：
                        {this.state.playData[0].duration}
                        {" · "}
                        演员：
                        <ActorItems
                          items={this.state.playData[0].actor.split(",")}
                        />{" "}
                      </div>{" "}
                    </div>
                  )}

                  <div
                    className="flexTags"
                    style={isMobile == false ? {} : { padding: "0 10px" }}
                  >
                    <TagItems items={this.state.tags} />
                  </div>
                </div>
              ) : (
                ""
              )}

              {isMobile == true ? (
                this.state.videoId != null &&
                this.state.videoId != "" &&
                this.state.playData != null &&
                this.state.playData != "" ? (
                  <div
                    className="ml-media-meta-comments"
                    data-toggle-comments=""
                    onClick={() => this.setState({ openComment: true })}
                  >
                    <h1 className="ml-content-header">
                      <span className="ml-content-header-title no-padding">
                        <CommentOutlined />
                        评论
                        <span className="ml-meta-comments-count">
                          <i className="icon comment-solid"></i>
                          <span className="comments-count">
                            {this.state.commentTotal == 0
                              ? "暂无"
                              : this.state.commentTotal}
                          </span>
                        </span>
                      </span>
                      <span className="ml-content-header-sub-title">
                        欢迎讨论剧情或分享心得{" "}
                      </span>
                      <div className="ml-content-float-right">
                        {this.state.openComment == false ? (
                          <UpOutlined />
                        ) : (
                          <DownOutlined />
                        )}
                      </div>
                    </h1>
                  </div>
                ) : null
              ) : null}
              {isMobile == false ? (
                this.state.videoId != null &&
                this.state.videoId != "" &&
                this.state.playData != null &&
                this.state.playData != "" ? (
                  <CommentArea
                    videoId={this.state.videoId}
                    signGet={this.state.signGet}
                  />
                ) : (
                  ""
                )
              ) : (
                <Drawer
                  className="comment-drawer"
                  height={"64vh"}
                  title="评论区"
                  placement={"bottom"}
                  closable={false}
                  onClose={() => this.setState({ openComment: false })}
                  open={this.state.openComment}
                  key={"comment-bottom"}
                >
                  {this.state.videoId != null &&
                  this.state.videoId != "" &&
                  this.state.playData != null &&
                  this.state.playData != "" ? (
                    <CommentArea
                      videoId={this.state.videoId}
                      signGet={this.state.signGet}
                    />
                  ) : (
                    ""
                  )}
                </Drawer>
              )}
            </div>
          </Col>
          {isMobile === false ? (
            //<Col xs={6} sm={6} style={{ padding: "10px" }}><div className='block' style={this.state.playerFullScreen == true ? { width: "100%", height: "100%", display: "none" } : { width: "100%", height: "100%", display: "block" }}></div></Col>
            <Col
              xs={6}
              sm={6}
              style={
                this.state.playerFullScreen == true
                  ? { display: "none" }
                  : { padding: "10px" }
              }
            >
              {this.state.curDomain == "xplex.net" ||
              this.state.curDomain == "xplex.click" ||
              this.state.curDomain == "xplex.press" ||
              this.state.curDomain == "xplex.link" ? (
                <div
                  style={{
                    width: "100%",
                    margin: "0px auto",
                    overflow: "hidden",
                  }}
                >
                  {this.state.curDomain == "xplex.net" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5117362&size=300x250"
                      width="300"
                      height="260"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}
                  {this.state.curDomain == "xplex.net" ? (
                    <iframe
                      src="https://adserver.juicyads.com/adshow.php?adzone=1041597"
                      width="320"
                      height="280"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}

                  {this.state.curDomain == "xplex.click" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5117360&size=300x250"
                      width="300"
                      height="260"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}
                  {this.state.curDomain == "xplex.click" ? (
                    <iframe
                      src="https://adserver.juicyads.com/adshow.php?adzone=1041603"
                      width="320"
                      height="280"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}

                  {this.state.curDomain == "xplex.press" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5117364&size=300x250"
                      width="300"
                      height="260"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}
                  {this.state.curDomain == "xplex.press" ? (
                    <iframe
                      src="https://adserver.juicyads.com/adshow.php?adzone=1041606"
                      width="320"
                      height="280"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}
                  {this.state.curDomain == "xplex.link" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5253284&size=300x250"
                      width="300"
                      height="260"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}
                  {this.state.curDomain == "xplex.link" ? (
                    <iframe
                      src="https://adserver.juicyads.com/adshow.php?adzone=1051641"
                      width="320"
                      height="280"
                      style={{ marginBottom: "10px" }}
                    ></iframe>
                  ) : null}
                </div>
              ) : null}
              <h3>猜你喜欢</h3>
              {this.state.recommend[0] != null ? (
                <RecommendItems
                  items={this.state.recommend}
                  height={this.state.heightCard}
                  formatDate={this.formatDate}
                  goTo={this.goTo.bind(this)}
                  hd={this.hd}
                  nsfw={this.nsfw}
                />
              ) : (
                <RecommendEmpty
                  items={[
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                  ]}
                  height={this.state.heightCard}
                />
              )}
            </Col>
          ) : (
            <Col
              xs={24}
              sm={24}
              style={
                isMobile == true
                  ? { padding: "10px", borderTop: "1px solid #f2f2f2" }
                  : { padding: "10px" }
              }
            >
              {this.state.curDomain == "xplex.net" ||
              this.state.curDomain == "xplex.click" ||
              this.state.curDomain == "xplex.press" ||
              this.state.curDomain == "xplex.link" ? (
                <div
                  style={{
                    width: "max-content",
                    margin: "0px auto",
                    height: "260px",
                    lineHeight: " 0",
                    overflow: "hidden",
                  }}
                >
                  {this.state.curDomain == "xplex.net" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5117362&size=300x250"
                      width="300"
                      height="260"
                    ></iframe>
                  ) : null}

                  {this.state.curDomain == "xplex.click" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5117360&size=300x250"
                      width="300"
                      height="260"
                    ></iframe>
                  ) : null}

                  {this.state.curDomain == "xplex.press" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5117364&size=300x250"
                      width="300"
                      height="260"
                    ></iframe>
                  ) : null}
                  {this.state.curDomain == "xplex.link" ? (
                    <iframe
                      src="//a.magsrv.com/iframe.php?idzone=5253284&size=300x250"
                      width="300"
                      height="260"
                    ></iframe>
                  ) : null}
                </div>
              ) : null}
              <h3>猜你喜欢</h3>
              {this.state.recommend.length > 0 ? (
                <RecommendItemsMobile
                  items={this.state.recommend}
                  height={this.state.heightCard}
                  formatDate={this.formatDate}
                  goTo={this.goTo.bind(this)}
                  hd={this.hd}
                  nsfw={this.nsfw}
                />
              ) : (
                <RecommendEmptyMobile
                  items={[
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                  ]}
                  height={this.state.heightCard}
                />
              )}
            </Col>
          )}
        </Row>

        <Dialog
          visible={this.state.visibleSetting}
          type={this.state.visibleType}
          title={this.state.visibleTitle}
          onChangeReport={this.onChangeReport}
          reportValue={this.state.reportValue}
          onChangeCdn={this.onChangeCdn}
          cdnValue={this.state.cdnValue}
        />
      </div>
    );
  }
}

export default Detail;
