import React from "react";
import {
  message,
  Row,
  Col,
  Input,
  Button,
  Select,
  AutoComplete,
  Alert,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import md5 from "md5";
import momentZone from "moment-timezone";
import { encode, decode } from "js-base64";
import "../account.css";
import {
  UserOutlined,
  LockOutlined,
  LoadingOutlined,
  MailOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import * as qs from "qs";
import { Cookies } from "react-cookie";
import loginjpg from "./assets/login.png";
import regjpg from "./assets/reg.png";
import ReactGA from "react-ga4";

ReactGA.initialize("G-883ML6DGJC");

interface props {}
interface stateType {
  signGet: string;
  recommend: never[];
  username: string;
  password: string;
  email: string;
  types: string;
  forgetInt: number;
  forgetSelect: {}[];
  forgetActive: string;
  forget0Input: string;
  forget1Input: string;
  progress: boolean;
  width: number;
  loadingEvent: boolean;
  sendtime: number;
  captchaDisabled: boolean;
  captcha: any;
  loadingEventConfirm: boolean;
}

class Account extends React.PureComponent<props, stateType> {
  constructor(props: props | Readonly<props>) {
    const cookies = new Cookies();

    super(props);
    console.log("running");
    var date = md5(
      momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
    );
    this.state = {
      captcha: "",
      loadingEvent: false,
      sendtime: 0,
      captchaDisabled: false,
      signGet: date,
      recommend: [],
      username: "",
      password: "",
      email: "",
      types: "login",
      forgetInt: 0,
      forgetSelect: [
        { value: "username", lable: "用户名" },
        { value: "email", lable: "邮箱" },
      ],
      forgetActive: "username",
      forget0Input: "",
      forget1Input: "",
      progress: false,
      width: window.innerWidth,
      loadingEventConfirm: false,
    };
  }

  componentDidMount() {
    const _this = this;

    //this.state.cookies.remove('username')
    //this.state.cookies.remove('password')
    //this.state.cookies.remove('email')
    //this.state.cookies.set('username','')
    //this.state.cookies.set('password','')
    //this.state.cookies.set('email','')
    //console.log(this.state.usernameCookies)
    //console.log(this.state.passwordCookies)

    // instantiate Video.js//
    window.addEventListener("resize", this.handleWindowSizeChange);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query = params.get("action");

    const cookies = new Cookies();
    if (cookies.get("username") != null && cookies.get("username").length > 0) {
      this.setState({
        username: cookies.get("username"),
        password: cookies.get("password"),
      });
    } else {
      if (query == "verify") {
        window.location.replace("/account?action=login");
      }
    }

    if (query == "verify") {
      document.title = "验证账户 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/account?action=verify",
        title: "验证账户 - " + window.title,
      });
      setTimeout(() => {
        this.setState({ types: "verify" });
      }, 100);
    }
    if (query == "login") {
      document.title = "登录账户 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/account?action=login",
        title: "登录账户 - " + window.title,
      });
      setTimeout(() => {
        this.setState({ types: "login" });
      }, 100);
    }
    if (query == "register") {
      document.title = "注册账户 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/account?action=register",
        title: "注册账户 - " + window.title,
      });
      setTimeout(() => {
        this.setState({ types: "register" });
      }, 100);
    }
    if (query == "forget") {
      document.title = "忘记密码 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/account?action=forget",
        title: window.title + "忘记密码 - " + window.title,
      });
      setTimeout(() => {
        this.setState({ types: "forget" });
      }, 100);
    }
    if (query == null) {
      document.title = "登录账户 - " + window.title;
      ReactGA.send({
        hitType: "pageview",
        page: "/account?action=login",
        title: "登录账户 - " + window.title,
      });
      setTimeout(() => {
        window.location.href = "/account?action=login";
        this.setState({ types: "login" });
      }, 100);
    }
  }

  // destroy player on unmount
  componentWillUnmount() {}

  handleWindowSizeChange = () => {
    return () => {
      this.setState({ width: window.innerWidth });
    };
  };

  keyHash = (key: string) => {
    if (key == "400" || key.length == 0) {
      return "";
    } else {
      var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .add(1, "hours")
        .format("YYYY-MM-DD-HH");
      var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
        .subtract(1, "hours")
        .format("YYYY-MM-DD-HH");

      var add = encode(md5(date1 + "sign91")).replace("=", "");
      var subtract = encode(md5(date2 + "sign91")).replace("=", "");

      var length1 = key.length;
      var length2 = length1 - 20;
      var content1 = key.substring(length2, length1);
      var content2 = key.substring(0, length2);
      ///////////////////////////////////////////
      var keys = encode(this.state.signGet).replace("=", "");
      let reg = new RegExp("END", "g"); //g代表全部
      var keyHashFirst = content1.replace(reg, "");
      var keyHash = content2 + keyHashFirst;
      var keyResult = keyHash.replace(keys, "");
      var keyResult1 = keyResult.replace(subtract, "");
      var keyResult2 = keyResult1.replace(add, "");
      return decode(keyResult2.toString());
    }
  };

  handleUsername = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ username: value.target.value });
  };
  handlePassword = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ password: value.target.value });
  };
  handleEmail = (value: { target: { value: any } }) => {
    console.log(value);
    this.setState({ email: value.target.value });
  };
  handleForgetInput = (value: any) => {
    this.setState({ forget0Input: value });
  };
  handleForgetCaptchaInput = (value: { target: { value: any } }) => {
    this.setState({ forget1Input: value.target.value });
  };
  vaildInput = (value: string) => {
    function checkEmail(email: string) {
      return RegExp(
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      ).test(email);
    }

    var pattern = new RegExp(
      "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]"
    );

    if (value == "forget") {
      if (this.state.forgetActive == "email") {
        if (!checkEmail(this.state.forget0Input)) {
          this.error("邮箱格式错误");
          return false;
        }
      }
      if (this.state.forgetActive == "username") {
        if (checkEmail(this.state.forget0Input)) {
          this.error("用户名格式错误");
          return false;
        }
        if (pattern.test(this.state.forget0Input)) {
          this.error("用户名格式错误");
          return false;
        }
        if (this.state.username.length < 3 || this.state.username.length < 16) {
          this.error("用户名长度错误");
          return false;
        }
      }
      return "pass";
    }
    if (value == "captcha") {
      if (
        pattern.test(this.state.forget1Input) ||
        this.state.forget1Input.length > 6
      ) {
        this.error("验证码格式错误");
        return false;
      }
      if (this.state.forget1Input.length < 6) {
        this.error("验证码长度错误");
        return false;
      }
      return "pass";
    }
    if (value == "login") {
      if (
        pattern.test(this.state.username) ||
        this.state.username.length > 16
      ) {
        this.error("用户名格式错误");
        return false;
      }
      if (this.state.password.length > 32) {
        this.error("密码格式错误");
        return false;
      }
      if (this.state.username.length < 3) {
        this.error("用户名长度错误");
        return false;
      }
      if (this.state.password.length < 6) {
        this.error("密码长度错误");
        return false;
      }
      return "pass";
    }
    if (value == "register") {
      if (this.state.username.length < 3) {
        this.error("用户名长度错误");
        return false;
      }
      if (
        pattern.test(this.state.username) ||
        this.state.username.length > 16
      ) {
        this.error("用户名格式错误");
        return false;
      }
      if (this.state.password.length > 32 || this.state.password.length < 8) {
        this.error("密码长度错误");
        return false;
      }
      if (!checkEmail(this.state.email)) {
        this.error("邮箱格式错误");
        return false;
      }
      return "pass";
    }
  };

  changeType = (value: string) => {
    let navigate = useNavigate();
    return () => {
      this.setState({ types: "waiting", progress: false });
      setTimeout(() => {
        if (value == "login") {
          //window.location.href = "/account?action=login"
          navigate("../account?action=login", { replace: true });
          this.setState({ types: "login" });
          return false;
        }
        if (value == "register") {
          //window.location.href = "/account?action=register"
          navigate("../account?action=register", { replace: true });
          this.setState({ types: "register" });
          return false;
        }
        if (value == "forget") {
          //window.location.href = "/account?action=register"
          navigate("../account?action=forget", { replace: true });
          this.setState({ types: "forget" });
          return false;
        }
        if (value == "verify") {
          //window.location.href = "/account?action=register"
          navigate("../account?action=verify", { replace: true });
          this.setState({ types: "verify" });
          return false;
        }
      }, 500);
    };
  };

  setCookies = (name: any, key: any) => {
    console.log(name, key);
    const cookies = new Cookies();
    cookies.set(name, key);
    console.log(cookies.get("username"));
  };

  getCaptcha = () => {
    if (this.state.sendtime > 0) {
      return false;
    }
    this.setState({
      loadingEvent: true,
    });
    let that = this;
    axios
      .post(
        window.apiUrl + "/Application/ActiveEmail?sign=" + this.state.signGet,
        qs.stringify({
          username: this.state.username,
          password: this.state.password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: { data: any; status: any; headers: any }) {
        that.setState({
          sendtime: 60,
          captchaDisabled: true,
          loadingEvent: false,
        });
        that.success("验证码发送成功");

        setInterval(function () {
          if (that.state.sendtime > 1) {
            that.setState({
              sendtime: that.state.sendtime - 1,
            });
          }
          if (that.state.sendtime <= 1) {
            that.setState({
              sendtime: 0,
              captchaDisabled: false,
              loadingEvent: false,
            });
          }
        }, 1000);
      });
  };

  sumbitCaptcha = () => {
    if (this.state.captcha == "" || this.state.captcha == null) {
      this.error("验证码错误");
      return false;
    }
    this.setState({
      loadingEventConfirm: true,
    });
    const cookies = new Cookies();

    let that = this;
    if (this.state.username != null && cookies.get("username") != null) {
      axios
        .post(
          window.apiUrl +
            "/Application/ActiveEmailAction?sign=" +
            this.state.signGet,
          qs.stringify({
            user: this.state.username,
            captcha: this.state.captcha,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response: { data: any; status: any; headers: any }) {
          that.setState({
            loadingEventConfirm: false,
          });
          if (response.data.msg != "账户激活成功") {
            that.error("验证码错误");
            return false;
          }
          that.success("账户验证成功");

          cookies.set("vef", "active");
          window.location.replace("/index");
        });
    } else {
      this.setState({
        loadingEventConfirm: false,
      });
    }
  };

  loginAction = () => {
    const navigate = useNavigate();

    return () => {
      if (this.vaildInput("login") != "pass") {
        return false;
      }

      const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
      _this.setState({
        progress: true,
      });

      axios
        .post(
          window.apiUrl +
            "/Application/login_action?sign=" +
            this.state.signGet,
          qs.stringify({
            username: this.state.username,
            password: this.state.password,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response: { data: any; status: any; headers: any }) => {
          var decode5 = _this.keyHash(response.data);
          let reg = new RegExp("\\+", "g"); //g代表全部
          let newMsg = decode5.replace(reg, "  ");
          var result = JSON.parse(newMsg);
          const data = result;
          console.log(response.data);
          console.log(response.status);
          console.log(response.headers);
          console.log(this.state.username, this.state.password);
          this.setCookies("username", this.state.username);
          this.setCookies("password", this.state.password);
          this.setCookies("email", data.email);
          this.setCookies("vef", data.vef);
          this.setCookies("loginHash", data.hash);
          this.setCookies("joinDay", data.joinday);
          this.success("登录成功");
          if (data.vef != "active") {
            window.location.replace("/account?action=verify");
          } else {
            this.setState({
              progress: false,
            });

            window.location.replace("/index");
            this.forceUpdate();
          }
        })
        .catch(function () {
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          _this.error("验证失败，用户名或者密码错误");
          _this.setState({
            progress: false,
          });
        });
    };
  };

  registerAction = () => {
    const navigate = useNavigate();
    return () => {
      if (this.vaildInput("register") != "pass") {
        return false;
      }
      const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
      _this.setState({
        progress: true,
      });

      axios
        .post(
          window.apiUrl +
            "/Application/register_action?sign=" +
            this.state.signGet,
          qs.stringify({
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response: { data: any; status: any; headers: any }) {
          console.log(response.data);
          console.log(response.status);
          console.log(response.headers);
          _this.success("注册成功");
          _this.setState({
            progress: false,
          });
          _this.setCookies("username", _this.state.username);
          _this.setCookies("password", _this.state.password);
          _this.setCookies("email", _this.state.email);

          navigate("../account?action=verify", { replace: true });
        })
        .catch(function (error: { response: { status: number } }) {
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          if (error.response.status == 400) {
            _this.error("注册信息不符合规范");
          }
          if (error.response.status == 401) {
            _this.error("您的IP地址注册数量超出最大限制，请更换IP地址重试");
          }
          if (error.response.status == 402) {
            _this.error("您输入的邮箱已被使用");
          }
          if (error.response.status == 403) {
            _this.error("您输入的用户名已被使用");
          }
          _this.setState({
            progress: false,
          });
        });
    };
  };

  getEmail = (email: any) => {
    return email.replace(
      /(.{2})(.*)(?=@)/,
      function (gp1: any, gp2: any, gp3: any) {
        for (let i = 0; i < gp3.length; i++) {
          gp2 += "*";
        }
        return gp2;
      }
    );
  };

  forgetSendCaptchaAction = () => {
    if (this.vaildInput("forget") != "pass") {
      return false;
    }

    const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
    _this.setState({
      progress: true,
    });
    axios
      .post(
        window.apiUrl + "/Application/forget?sign=" + this.state.signGet,
        qs.stringify({ user: this.state.forget0Input }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response: {
        data: { msg: string };
        status: any;
        headers: any;
      }) {
        console.log(response.data);
        console.log(response.status);
        console.log(response.headers);
        if (response.data.msg == "找回密码操作验证码已发送") {
          _this.success(response.data.msg);
          _this.setState({
            forgetInt: 1,
          });
        } else {
          _this.error(response.data.msg);
        }
        _this.setState({
          progress: false,
        });
      })
      .catch(function () {});
  };

  forgetSubmitCaptchaAction = () => {
    const navigate = useNavigate();
    return () => {
      if (this.vaildInput("captcha") != "pass") {
        return false;
      }
      const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
      _this.setState({
        progress: true,
      });
      axios
        .post(
          window.apiUrl +
            "/Application/forgetAction?sign=" +
            this.state.signGet,
          qs.stringify({
            user: this.state.forget0Input,
            captcha: this.state.forget1Input,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response: {
          data: { msg: string };
          status: any;
          headers: any;
        }) {
          console.log(response.data);
          console.log(response.status);
          console.log(response.headers);
          if (
            response.data.msg ==
            "随机密码已重置成功并发送到你的邮箱，尽快登录修改新密码"
          ) {
            _this.success(response.data.msg);
            _this.setState({
              forgetInt: 0,
            });
          } else {
            _this.error(response.data.msg);
          }
          _this.setState({
            progress: false,
          });
          navigate("../account", { replace: true });
        })
        .catch(function () {});
    };
  };

  changeForgetActive = (event: any) => {
    this.setState({
      forgetActive: event,
    });
    setTimeout(() => {
      console.log(this.state.forgetActive);
    }, 200);
  };

  success = (msg: string) => {
    message.success(msg);
  };

  error = (msg: string) => {
    message.error(msg);
  };

  warning = (msg: any) => {
    message.warning(msg);
  };

  handleChangeCaptcha = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      this.setState({ captcha: inputValue });
      console.log(this.state.captcha);
    }
  };

  render() {
    const isMobile = this.state.width <= 820;
    const { Option } = Select;

    function Btn(props: { changeType: any; text: any; typeText: any }) {
      const changeType = props.changeType;
      const text = props.text;
      const typeText = props.typeText;
      const btn = (
        <Button key={"regbtn"} type="primary" onClick={changeType(typeText)}>
          {text}
        </Button>
      );
      return <div>{btn}</div>;
    }

    function SumbitBtn(props: { action: any; progress: any; actionText: any }) {
      const action = props.action;
      const progress = props.progress;
      const actionText = props.actionText;
      const btn = (
        <Button type="primary" onClick={action()}>
          {progress == true ? <LoadingOutlined /> : actionText}
        </Button>
      );
      return <div>{btn}</div>;
    }

    function Forget(props: { changeType: any }) {
      const changeType = props.changeType;
      const btn = (
        <a onClick={changeType("forget")} className="forgetPassword">
          忘记密码? 点击此处找回
        </a>
      );
      return <div style={{ width: "50%", float: "left" }}>{btn}</div>;
    }
    function Register(props: { changeType: any }) {
      const changeType = props.changeType;
      const btn = (
        <a onClick={changeType("register")} className="registerText">
          注册账户
        </a>
      );
      return (
        <div style={{ width: "50%", float: "right", textAlign: "right" }}>
          {btn}
        </div>
      );
    }

    function ForgetSelect(props: {
      forgetSelect: any;
      forgetActive: any;
      changeForgetActive: any;
    }) {
      const forgetSelect = props.forgetSelect;
      const forgetActive = props.forgetActive;
      const changeForgetActive = props.changeForgetActive;
      const listItem = forgetSelect.map(
        (item: { lable: React.Key | null | undefined; value: any }, i: any) => (
          <Option key={item.lable} value={item.value}>
            {item.lable}
          </Option>
        )
      );

      return (
        <Select
          onChange={changeForgetActive}
          defaultValue={forgetActive}
          style={{ width: "30%" }}
        >
          {listItem}
        </Select>
      );
    }
    const cookie = new Cookies();

    return (
      <div
        id="accountPage"
        className={isMobile == false ? "" : "accountMobile"}
      >
        {isMobile == false ? <div className="navBlock"></div> : null}

        {this.state.types == "verify" ? (
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="account-left account-verify">
                <h2>
                  <UnlockOutlined />
                  &nbsp; 激活账户
                </h2>
                <Alert
                  message="登录前，请先验证您的邮箱"
                  type="success"
                  showIcon
                  closable
                />
                <br />
                <Input.Group compact>
                  <Input
                    style={{ width: "calc(100% - 200px)" }}
                    defaultValue={this.getEmail(cookie.get("email"))}
                    disabled={true}
                    placeholder="点击右侧获取验证码"
                  />
                  <Button
                    className={this.state.sendtime > 0 ? "alreadySend" : ""}
                    type="primary"
                    loading={this.state.loadingEvent}
                    onClick={() => this.getCaptcha()}
                  >
                    {this.state.sendtime == 0
                      ? "获取验证码"
                      : this.state.sendtime + "秒"}
                  </Button>
                </Input.Group>
                <Input
                  onChange={this.handleChangeCaptcha}
                  //onBlur={handleBlur}
                  placeholder="输入验证码"
                  maxLength={6}
                  style={{
                    marginBottom: "10px",
                    padding: "10px",
                    border: "none",
                  }}
                />
                <br />
                <Button
                  className="confirm-verify"
                  style={{ width: "100% !important" }}
                  onClick={() => this.sumbitCaptcha()}
                  loading={this.state.loadingEventConfirm}
                >
                  确认
                </Button>
              </div>
            </Col>
            {isMobile == false ? (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div className="account-right">
                  <img src={loginjpg} style={{ opacity: "0.1" }}></img>
                </div>
              </Col>
            ) : null}
          </Row>
        ) : (
          ""
        )}

        {this.state.types == "login" ? (
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div className="account-left">
                <Alert
                  message="Xplex为91HUB中文版，两站之间可交互登录"
                  type="success"
                  showIcon
                  closable
                />
                <h1>账号登录</h1>
                <h5>登录后享受更多功能</h5>
                {/***
                                     * 
                                     *                                 <h3>拥有账号可以享受以下功能</h3>
                                <ul>
                                    <li><p><CloudSyncOutlined />上传/下载视频，或评论互动</p></li>
                                    <li><p><FolderAddOutlined />收藏视频，精彩内容不再错过</p></li>
                                    <li><p><FileSyncOutlined />历史记录，3端在线同步</p></li>
                                    <li><p><VideoCameraOutlined />超清视频，随时随地在线观看</p></li>
                                </ul>
                                     */}

                <Input
                  onChange={this.handleUsername}
                  placeholder="用户名"
                  showCount
                  maxLength={14}
                  prefix={<UserOutlined />}
                />
                <Input.Password
                  onChange={this.handlePassword}
                  placeholder="密码"
                  prefix={<LockOutlined />}
                />
                <SumbitBtn
                  action={this.loginAction}
                  progress={this.state.progress}
                  actionText={"登录"}
                />
                <Forget changeType={this.changeType.bind(this)} />
                <Register changeType={this.changeType.bind(this)} />
              </div>
            </Col>
            {isMobile == false ? (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div className="account-right">
                  <img src={loginjpg} style={{ opacity: "0.1" }}></img>
                </div>
              </Col>
            ) : null}
          </Row>
        ) : (
          ""
        )}

        {this.state.types == "register" ? (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={
                isMobile == false
                  ? { maxWidth: "350px", margin: "0 auto" }
                  : { padding: "0 10px" }
              }
            >
              <div className="register-main">
                <h1>注册账号</h1>
                <h5>加入后可享受更多功能</h5>
                <Input
                  onChange={this.handleUsername}
                  placeholder="用户名"
                  showCount
                  maxLength={14}
                  prefix={<UserOutlined />}
                />
                <Input.Password
                  onChange={this.handlePassword}
                  placeholder="密码"
                  maxLength={32}
                  prefix={<LockOutlined />}
                />
                <Input
                  onChange={this.handleEmail}
                  placeholder="邮箱地址"
                  showCount
                  maxLength={50}
                  prefix={<MailOutlined />}
                />
                <SumbitBtn
                  action={this.registerAction}
                  progress={this.state.progress}
                  actionText={"提交注册"}
                />
                <Btn
                  changeType={this.changeType.bind(this)}
                  text={"返回登录"}
                  typeText={"login"}
                />
                <Forget changeType={this.changeType.bind(this)} />
              </div>
            </Col>
            {isMobile == false ? (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <div className="account-right">
                  <img src={regjpg} style={{ opacity: "0.1" }}></img>
                </div>
              </Col>
            ) : null}
          </Row>
        ) : (
          ""
        )}

        {this.state.types == "waiting" ? (
          <div className="exchangeType">
            <h1 style={{ fontSize: "60px" }}>
              {" "}
              <LoadingOutlined />
            </h1>
          </div>
        ) : (
          ""
        )}

        {this.state.types == "forget" ? (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ maxWidth: "350px", margin: "0 auto" }}
            >
              <div className="forget-main">
                <h1>找回密码</h1>
                {this.state.forgetInt == 0 ? (
                  <Input.Group compact>
                    <ForgetSelect
                      forgetSelect={this.state.forgetSelect}
                      forgetActive={this.state.forgetActive}
                      changeForgetActive={this.changeForgetActive.bind(this)}
                    />
                    <AutoComplete
                      onChange={this.handleForgetInput}
                      style={{ width: "70%" }}
                      placeholder="用户名或邮箱"
                      options={this.state.forgetSelect}
                    />
                  </Input.Group>
                ) : (
                  <Input
                    onChange={this.handleForgetCaptchaInput}
                    placeholder="验证码"
                    showCount
                    maxLength={6}
                    prefix={<LockOutlined />}
                  />
                )}

                <br />
                {this.state.forgetInt == 0 ? (
                  <Button type="primary" onClick={this.forgetSendCaptchaAction}>
                    {this.state.progress == true ? (
                      <LoadingOutlined />
                    ) : (
                      "获取验证码"
                    )}
                  </Button>
                ) : (
                  <SumbitBtn
                    action={this.forgetSubmitCaptchaAction}
                    progress={this.state.progress}
                    actionText={"提交验证"}
                  />
                )}

                <Btn
                  changeType={this.changeType.bind(this)}
                  text={"返回登录"}
                  typeText={"login"}
                />
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Account;
